import React from 'react';
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
    createStyles({
        link: {
            color: theme.palette.primary.main
        }
    })
);
const ExternalLink = ({ href, label }) => {
    const classes = useStyles();

    return (
        <a className={classes.link} href={href} target="_blank" rel="noopener noreferrer">{label}</a>
    )
}
ExternalLink.propTypes = {
    href: PropTypes.string,
    label: PropTypes.string
}
export default ExternalLink;