export const routes = {
    APP_ROUTE: {
        path: '/',
        name: 'APP',
        displayText: "App main route"
    },
    MAP_ROUTE: {
        path: '/map',
        name: 'MAPP',
        displayText: "MAP route"
    },
    PRIVACY_POLICY_ROUTE: {
        path: '/privacy',
        name: 'PRIVACY_POLICY',
        displayText: "Privacy policy route"
    },
}