import { constants as C } from '../actions/constants';
import { CoordinateMapper } from '../../utils/CoordinateMapper';
import { MAP_CONSTANTS } from '../../components/MapWidget/mapConstants';

export const mapProperties = (state = { center: MAP_CONSTANTS.defaultCenter, zoom: MAP_CONSTANTS.defaultZoom, loading: true }, action) => {
    switch (action.type) {
        case C.SET_MAP_PROPERTIES:
            // /!\ here is the manipulation of translation of the center of the map
            // Each time an update of center is provided, data is mapped to actual Lambert 2008 projection
            // if (action.payload.center) {
            //     const coordinateMapper = new CoordinateMapper();
            //     action.payload.center = coordinateMapper.mapCoordinate(action.payload.center);
            // }
            return action.payload
        default:
            return state
    }
}