import React from 'react';
import { Typography, AppBar, Toolbar, Grid, Divider, Button, Icon } from '@material-ui/core';
import { ExternalLink } from '../ui-kit/ExternalLink';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { RESA_LOGO } from '../../assets';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            color: "#6C6B6E",
            [theme.breakpoints.up('sm')]: {
                marginRight: theme.spacing(8),
                marginLeft: theme.spacing(8)
            },
        },
        title: {
            fontWeight: 'bold'
        },
        policy: {
            [theme.breakpoints.only('xs')]: {
                marginTop: theme.spacing(12),
            },
            marginTop: theme.spacing(10),
            marginBottom: theme.spacing(10),
        },
        appBar: {
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            })
        },
        logo: {
            '& img': {
                maxHeight: "50px",
                cursor: "pointer"
            },
            [theme.breakpoints.only('xs')]: {
                display: 'none'
            }
        },
        logoContainer: {
            display: 'flex',
            justifyContent: 'center'
        },
        backButton: {
            display: 'flex',
            justifyContent: 'flex-end'
        }
    })
);

const PrivacyPolicyPage = ({ history }) => {
    const classes = useStyles();

    const backToMap = () => {
        history.push('/map');
    }

    return (
        <React.Fragment>
            {/** TOPBAR */}
            <AppBar
                position="fixed"
                className={classes.appBar}
                color="primary"
            >
                <Toolbar>
                    <Grid container alignItems="center">
                        <Grid item xs>
                            <Typography component="h1">Politique vie privée</Typography>
                        </Grid>
                        <Grid item xs className={clsx(classes.logo, classes.logoContainer)}>
                            <a href="http://resa.be" target="_blank" rel="noopener noreferrer">
                                <img src={RESA_LOGO} alt="Logo Resa" />
                            </a>
                        </Grid>
                        <Grid item xs className={classes.backButton} >
                            <Button
                                onClick={backToMap}
                                variant="contained"
                                color="secondary">
                                <Icon>arrow_back_sharp</Icon>
                                Retour à la carte
                            </Button>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <div className={classes.root}>
                {/** VERSION FRANCAISE */}
                <div className={classes.policy}>
                    <Typography className={classes.title} component="h2">Politique « Vie Privée »</Typography>
                    <ul>
                        <li><Typography className={classes.title} component="h3">Objectif</Typography></li>
                    </ul>
                    <Typography>
                        Le présent document constitue la politique « Vie Privée » mise en œuvre par RESA dans le cadre de ses activités.

        La protection de votre vie privée et de vos données à caractère personnel est d’une importance capitale pour RESA.

        Cette politique « Vie Privée » est rédigée afin de garantir le respect du Règlement européen 2016/679 du 27 avril 2016 relatif à la protection des personnes physiques à l’égard du traitement des données à caractère personnel et à la libre circulation de ces données, et abrogeant la Directive 95/46/EC (Règlement général sur la protection des données, ou RGPD).

        Cette politique « Vie Privée » a pour but de vous informer de manière complète sur le sujet et explique comment nous collectons, utilisons et conservons vos données à caractère personnel.
</Typography>
                    <ul>
                        <li><Typography className={classes.title} component="h3">Quelle est la portée de cette politique ? </Typography></li>
                    </ul>
                    <Typography className={classes.title} component="h3">Que recouvre le « traitement de vos données » et qui en est responsable ?</Typography>
                    <Typography>
                        Nous recueillons et utilisons uniquement les données personnelles qui sont nécessaires dans le cadre de nos activités et qui nous permettent de vous proposer des services de qualité.

                    RESA S.A. Intercommunale, ayant son siège social à 4000 Liège Rue Sainte-Marie 11, est Responsable du traitement des données à caractère personnel qu’elle est amenée à traiter.

                    Nous sommes en conséquence votre partenaire ainsi que celui de l’Autorité de protection des données instituée par la loi du 03 décembre 2017 pour toute question relative à l’utilisation de vos données par notre société.

                    Pour certains services, nous faisons appel à des tiers spécialisés qui agissent  comme sous-traitants. Ceux-ci doivent alors suivre nos directives et respecter notre politique « Vie Privée ». Dans d’autres cas, ces tiers sont aussi co-responsables du traitement et doivent de leur côté respecter leurs obligations légales en la matière.

                    Nous nous assurons que les sous-traitants ne reçoivent que les données strictement nécessaires pour exécuter la partie du contrat qui leur incombe.
</Typography>
                    <ul>
                        <li><Typography className={classes.title} component="h3">Quelles données sont couvertes par notre politique ?</Typography></li>
                    </ul>
                    <Typography>
                        Les données couvertes par la présente politique sont les données à caractère personnel des personnes physiques, c’est-à-dire des données qui permettent directement ou indirectement d’identifier une personne physique.

                        Dans le cadre de vos relations et interactions avec RESA, nous pouvons être amenés à collecter différentes données à caractère personnel telles que :
</Typography>
                    <ul>
                        <li><Typography>
                            des données d’identification et de contact (exemples : vos titre, nom, adresse, date et lieu de naissance, numéro de registre national, numéro de compte, numéro de téléphone, adresse mail, code EAN, profession) ;
</Typography></li>
                        <li><Typography>
                            situation familiale (exemples : état civil, nombre d’enfants);
</Typography></li>
                        <li> <Typography>
                            données bancaires, financières et transactionnelles (exemples : coordonnées bancaires, numéros de compte, données relatives aux virements y compris la communication, et, de façon générale, toutes données enregistrées lors de vos transferts bancaires) ;
</Typography></li>
                        <li><Typography>
                            données relatives à vos comportements et habitudes concernant l’utilisation de nos canaux (exemples : notre site Internet) ;
</Typography></li>
                    </ul>
                    <Typography>
                        Nous ne traitons jamais de données relatives à vos origines raciales ou ethniques, vos opinions politiques, votre religion, vos convictions philosophiques ou l’appartenance syndicale ;  vos données génétiques, votre vie sexuelle ou orientation sexuelle
</Typography>
                    <ul>
                        <li> <Typography className={classes.title} component="h3">Principes directeurs pour traiter les données à caractère personnel</Typography></li>
                    </ul>
                    <Typography>
                        RESA respectera entre autres les principes suivants lors des traitements de données à caractère personnel dans le cadre de la gestion et de l’exécution de ses missions :
            </Typography>
                    <ul>
                        <li>
                            <Typography>
                                Traitement de données licite : RESA traite les données à caractère personnel d’une manière licite dans le cadre de ses activités ;
            </Typography></li>
                        <li>
                            <Typography>
                                Minimisation du traitement des données : RESA limite le traitement des données à caractère personnel à ce qui est nécessaire dans le cadre de ses activités ;
            </Typography></li>
                        <li>
                            <Typography>
                                Exactitude des données à caractère personnel : RESA prend toutes les mesures raisonnables pour veiller à ce que les données à caractère personnel soient exactes et qu’elles soient rectifiées et/ou effacées sans tarder si elles n’apparaissent plus exactes ;
            </Typography></li>
                        <li>
                            <Typography>
                                Limitation du traitement et de la conservation : RESA ne traitera et ne conservera pas plus longtemps que nécessaire à la réalisation de ses activités les données à caractère personnel ;
            </Typography></li>
                        <li>
                            <Typography>
                                Mesures de sécurité : RESA prend les mesures techniques et/ou organisationnelles nécessaires et adéquates pour la sécurité des données à caractère personnel.
            </Typography></li>
                    </ul>
                    <br />
                    <ul>
                        <li>
                            <Typography className={classes.title} component="h3">Quand vos données à caractère personnel sont-elles collectées ? </Typography></li>
                    </ul>
                    <Typography>
                        Les données que nous utilisons peuvent être collectées directement auprès de vous ou obtenues des sources suivantes, dans le but de vérifier ou d’enrichir nos bases de données :
            </Typography>
                    <ul>
                        <li>
                            <Typography>
                                des publications/bases de données rendues accessibles par les autorités officielles (exemple: le Moniteur Belge) ;
            </Typography></li>
                        <li>
                            <Typography>
                                nos entreprises clientes ou nos fournisseurs de services ;
            </Typography></li>
                        <li>
                            <Typography>
                                des sites internet/pages de réseaux sociaux contenant des informations que vous avez rendues publiques (exemple : votre site web ou réseau social) ;
            </Typography></li>
                        <li>
                            <Typography>
                                des bases de données rendues publiques par des tiers.
            </Typography></li>
                    </ul>
                    <Typography>
                        Certaines de vos données peuvent également être collectées par RESA notamment :
            </Typography>
                    <ul>
                        <li>
                            <Typography>
                                quand vous devenez utilisateur du réseau de distribution (URD), client social, fournisseur ou prestataire de services;
            </Typography></li>
                        <li>
                            <Typography>
                                quand vous vous enregistrez pour utiliser nos services en ligne (à chaque identification ou utilisation) ;
            </Typography></li>
                        <li>
                            <Typography>
                                quand vous remplissez les formulaires que nous vous soumettons ;
            </Typography></li>
                        <li>
                            <Typography>
                                quand vous utilisez nos services suite à la signature d’un contrat ;
            </Typography></li>
                        <li>
                            <Typography>
                                quand vous nous contactez par les différents canaux mis à votre disposition ;
            </Typography></li>
                        <li>
                            <Typography>
                                quand vos données nous sont transmises par des tiers autorisés ou des fournisseurs professionnels;
            </Typography></li>
                        <li>
                            <Typography>
                                quand vos données nous sont transmises par des tiers autorisés ou des fournisseurs professionnels;
            </Typography></li>
                        <li>
                            <Typography>
                                quand vous êtes filmés par nos caméras de surveillance situées dans et aux abords de nos locaux/immeubles.
            </Typography></li>
                    </ul>
                    <Typography>
                        Les images sont enregistrées uniquement dans le but de préserver la sécurité des biens et des personnes ainsi que de prévenir les abus, fraudes et autres infractions envers nos clients et/ou notre personnel (leur présence est signalée par des autocollants munis de nos coordonnées de contact).
            </Typography>
                    <ul>
                        <li>
                            <Typography className={classes.title} component="h3">
                                Sur quel fondement et pourquoi utilisons-nous vos données personnelles ?
            </Typography></li>
                    </ul>
                    <Typography>
                        Nous traitons vos données à caractère personnel en vue de diverses finalités. Pour chaque traitement, seules les données pertinentes par rapport à la finalité poursuivie sont traitées.
            </Typography>
                    <Typography>
                        De manière générale, nous utilisons vos données à caractère personnel :
            </Typography>
                    <ul>
                        <li>
                            <Typography>
                                dans le cadre de l’exécution d’un contrat ou la prise de mesures précontractuelles;
            </Typography></li>
                        <li>
                            <Typography>
                                afin de nous conformer aux dispositions légales et réglementaires auxquelles nous sommes soumis ;
            </Typography></li>
                        <li>
                            <Typography>
                                pour des raisons qui relèvent de l’intérêt légitime de la société (voyez des illustrations ci-dessous). Lorsque nous effectuons ce type de traitement, nous veillons à préserver l’équilibre entre cet intérêt légitime et le respect de votre vie privée ;
            </Typography></li>
                        <li>
                            <Typography>
                                quand nous avons obtenu votre consentement
            </Typography></li>
                    </ul>
                    <Typography>
                        Les données à caractère personnel sont traitées par RESA pour les finalités qui incluent, mais ne sont pas limitées à :
            </Typography>
                    <ul>
                        <li>
                            <Typography>
                                vous fournir des informations relatives à nos services ;
            </Typography></li>
                        <li>
                            <Typography>
                                vous assister et répondre à vos demandes ou dans le cadre de la gestion d’une plainte ;
            </Typography></li>
                        <li>
                            <Typography>
                                permettre la bonne exécution des conventions conclues ;
            </Typography></li>
                        <li>
                            <Typography>
                                assurer la gestion financière et comptable de RESA ;
            </Typography></li>
                        <li>
                            <Typography>
                                assurer une bonne gestion des utilisateurs de réseau, des clients sociaux, des fournisseurs ainsi que de nos réseaux et installations qui y sont liées;
            </Typography></li>
                        <li>
                            <Typography>
                                répondre aux obligations légales parmi lesquelles les réponses aux demandes de la CWaPE, d’autorités publiques ou judiciaires dûment autorisées ;
            </Typography></li>
                        <li>
                            <Typography>
                                répondre à un traitement nécessaire en vue de la réalisation de nos missions d’intérêts public ou relevant de l’exercice de l’autorité publique, il s’agit notamment d’assurer le raccordement au réseau de tout client final qui le demande, de valider et de transmettre aux fournisseurs d’énergie les relevés d’index et la consommation d’énergie; de gérer techniquement les flux d’électricité et de gaz, de comptage de ceux-ci ; ou de prendre toutes les mesures adéquates favorables à l’utilisation rationnelle de l’énergie…
            </Typography></li>
                        <li>
                            <Typography>
                                répondre à nos missions légales de service public lorsque RESA assure la mission de fournisseur d’énergie envers les clients protégés au tarif social ou assure, à titre temporaire et dans les cas spécifiquement prévus par le Gouvernement après avis de la CWaPE, la fourniture des clients finals qui se retrouvent provisoirement sans contrat de fourniture ;
            </Typography></li>
                        <li>
                            <Typography>
                                détecter et prévenir des abus et des fraudes : nous traitons et gérons des données de contact et de sécurité (lecteur de carte, mot de passe…) afin de valider ou suivre et assurer la sécurité de nos réseaux et installations;
            </Typography></li>
                        <li>
                            <Typography>
                                afin d’assurer la fourniture de services en faisant appel à des sous-traitants;
            </Typography></li>
                        <li>
                            <Typography>
                                suivi de nos activités (mesure d’intervention, du nombre de rendez-vous, du nombre d’appels, de visites…) ;
            </Typography></li>
                        <li>
                            <Typography>
                                amélioration de la qualité du service individuel à nos clients ;
            </Typography></li>
                        <li>
                            <Typography>
                                assurer la sécurisation de nos locaux et infrastructures, ainsi que des personnes en ces lieux.
            </Typography></li>
                    </ul>
                    <br />
                    <ul>
                        <li>
                            <Typography className={classes.title} component="h3">
                                Qui a accès à vos données et à qui sont-elles transférées ?
            </Typography></li>
                    </ul>
                    <Typography>
                        Seuls les utilisateurs autorisés ont accès à vos données à caractère personnel afin d’accomplir les finalités précitées. Il faut entendre par utilisateur autorisé les personnes qui, dans le cadre de l’exercice de leur fonction au sein de RESA sont autorisées, dans le cadre des activités réalisées, à traiter des données à caractère personnel sur la base des directives de RESA.
            </Typography>
                    <Typography>
                        Afin d’accomplir les finalités précitées, RESA peut divulguer vos données personnelles à/aux :
            </Typography>
                    <ul>
                        <li>
                            <Typography>
                                un actuaire ;
            </Typography></li>
                        <li>
                            <Typography>
                                un auditeur externe ;
            </Typography></li>
                        <li>
                            <Typography>
                                un commissaire agréé ;
            </Typography></li>
                        <li>
                            <Typography>
                                un conseiller juridique ;
            </Typography></li>
                        <li>
                            <Typography>
                                un consultant financier ;
            </Typography></li>
                        <li>
                            <Typography>
                                un autre professionnel et/ou prestataire de services/conseiller ;
            </Typography></li>
                        <li>
                            <Typography>
                                un secrétariat social, les organismes bancaires, les assureurs/Fonds ;
            </Typography></li>
                        <li>
                            <Typography>
                                nos calls center ;
            </Typography></li>
                        <li>
                            <Typography>
                                des prestataires de services (ex : sous-traitants, dépanneurs…) ;
            </Typography></li>
                        <li>
                            <Typography>
                                un fournisseur d’énergie
            </Typography></li>
                        <li>
                            <Typography>
                                des entreprises IT ou des prestataires de services pour des programmes software et le stockage de données électroniques (serveurs, etc.) ;
            </Typography></li>
                        <li>
                            <Typography>
                                des autorités judiciaires, administratives ou des services de police ;
            </Typography></li>
                        <li>
                            <Typography>
                                des autorités de contrôle.
            </Typography></li>
                    </ul>
                    <ul><li>
                        <Typography className={classes.title} component="h3">
                            Combien de temps conservons-nous vos données ?
            </Typography>
                    </li></ul>
                    <Typography>
                        Nous conservons vos données personnelles pour la plus longue des durées nécessaires au respect des dispositions légales et réglementaires applicables ou une autre durée compte tenu des contraintes opérationnelles telles qu’une bonne tenue de la comptabilité, un management efficace et les réponses aux demandes en justice ou du régulateur.

            Les données relatives aux utilisateurs de réseaux et/ou aux clients sociaux ou protégés sont conservées pendant la durée de vie des relations juridiques auxquelles elles se rapportent et pendant une période de principe de dix ans après la fin de celles-ci.

            Certaines données sont archivées pour des durées plus longues afin de répondre à nos obligations légales et à des fins de preuve notamment pour sauvegarder vos droits et les droits de notre société. Ces données archivées sont uniquement accessibles pour des besoins de preuve en justice, de contrôle par une Autorité autorisée (par exemple par l’autorité fiscale), pour des raisons de production de document devant les autorités judiciaires, administratives ou des services de police.
            </Typography>
                    <ul>
                        <li>
                            <Typography className={classes.title} component="h3">
                                Sécurité et confidentialité
            </Typography></li>
                    </ul>
                    <Typography>
                        RESA s’engage à adopter les mesures techniques, physiques et organisationnelles nécessaires et adéquates pour protéger les données à caractère personnel contre les accès non autorisés, le traitement illicite et non autorisé, la perte ou le dommage accidentel, et la destruction non autorisée. Ces mesures sont régulièrement évaluées et si nécessaire actualisées en vue de garantir une protection maximale des données à caractère personnel des personnes concernées.

            En cas de violation ou de fuite informatique, comme décrite ci-dessous, RESA prend les mesures nécessaires/adéquates pour en constater l’étendue et les conséquences, y mettre fin le plus vite possible et, le cas échéant, limiter son impact pour les personnes concernées.
            </Typography>
                    <ul>
                        <li>
                            <Typography className={classes.title} component="h3">
                                Quels sont vos droits et comment les exercer ?
            </Typography></li>
                    </ul>
                    <br />
                    <ul>
                        <li>
                            <Typography className={classes.title} component="h3">
                                Droits des personnes concernées
            </Typography></li>
                    </ul>
                    <Typography>
                        Conformément à la règlementation applicable, vous disposez de différents droits :
            </Typography>
                    <ul>
                        <li>
                            <Typography>
                                Le droit de demander l’accès aux données à caractère personnel (A)
            </Typography></li>
                        <li>
                            <Typography>
                                Le droit à la rectification (A)
            </Typography></li>
                        <li>
                            <Typography>
                                Le droit à l’effacement des données(A)
            </Typography></li>
                        <li>
                            <Typography>
                                Le droit de s’opposer au traitement (B)
            </Typography></li>
                        <li>
                            <Typography>
                                Le droit de retirer son consentement (B)
            </Typography></li>
                        <li>
                            <Typography>
                                Le droit de demander une limitation du traitement (B)
            </Typography></li>
                        <li>
                            <Typography>
                                Le droit à la portabilité des données (C)
            </Typography></li>
                    </ul>
                    <ul>
                        <li>
                            <Typography className={classes.title} component="h3">
                                Droit d’accès, de rectification et d’effacement
            </Typography></li>
                    </ul>
                    <Typography>
                        Toute personne concernée a le droit de faire une demande d’accès. Si une personne concernée exerce ce droit, RESA est tenue de lui fournir des informations à ce sujet, en ce compris :
            </Typography>
                    <ul>
                        <li>
                            <Typography>
                                de donner une description et une copie des données à caractère personnel;
            </Typography></li>
                        <li>
                            <Typography>
                                d’informer la personne concernée des raisons pour lesquelles RESA traite ces données.
            </Typography></li>
                    </ul>
                    <Typography>
                        Si des données sont inexactes ou incomplètes, la personne concernée peut demander de les rectifier.

            Dans certaines circonstances, la personne concernée peut, conformément à la règlementation en matière de protection des données, demander l’effacement d’une donnée à caractère personnel la concernant, entre autres si la donnée à caractère personnel n’est plus nécessaire pour les finalités pour lesquelles elle avait été collectée ou traitée. RESA peut cependant refuser d’effacer ces données, par exemple pour l’introduction, la mise en œuvre ou la preuve d’un droit en justice.

            Dans un souci de tenir vos données parfaitement à jour, nous vous prions de nous informer, dans les meilleurs délais, de tout changement (exemple : changement d’état civil, de domicile).
            </Typography>
                    <ul>
                        <li>
                            <Typography className={classes.title} component="h3">
                                Droit d’opposition et de limitation au traitement de vos données et droit de retirer votre consentement
            </Typography>
                        </li>
                    </ul>
                    <Typography>
                        Vous avez le droit de vous opposer à certains traitements de vos données à caractère personnel que nous souhaiterions effectuer. Vous pouvez également demander la limitation du traitement de vos données.
            </Typography>
                    <Typography>
                        Ce droit ne peut cependant s’exercer que dans certaines conditions :
            </Typography>
                    <ul>
                        <li>
                            <Typography>
                                votre demande doit être datée et signée ;
            </Typography></li>
                        <li>
                            <Typography>
                                vous devez avoir des raisons sérieuses et légitimes tenant à votre situation particulière pour vous opposer à ce que le traitement ait lieu. En cas d’opposition justifiée, le traitement en question ne pourra plus porter sur ces données.
            </Typography></li>
                        <li></li>
                    </ul>
                    <Typography>
                        Par contre, vous ne pouvez pas vous opposer à un traitement nécessaire à l’exécution d’un contrat conclu avec vous ou à l’exécution des mesures précontractuelles prises à votre demande; ni au respect de toute disposition légale ou réglementaire à laquelle nous sommes soumis.

            Si vous avez donné votre consentement au traitement de vos données personnelles, vous avez le droit de retirer ce consentement à tout moment.
            </Typography>
                    <Typography className={classes.title} component="h3">
                        Le droit à la portabilité
            </Typography>
                    <Typography>
                        Lorsque cela est nécessaire et dans la mesure où cela est applicable, la personne concernée peut demander de recevoir certaines données à caractère personnel qu’elle a fournies à RESA dans le cadre de la gestion et de l’exécution de ses activités, et de transférer ces données vers un autre Responsable du traitement. Lorsque cela est techniquement possible, la personne concernée peut demander à RESA de transférer directement ces données vers un autre Responsable du traitement.
            </Typography>
                    <ul>
                        <li>
                            <Typography className={classes.title} component="h3">
                                A qui vous adresser ?
            </Typography></li>
                    </ul>
                    <Typography>
                        Si la personne concernée souhaite exercer ses droits relativement à ses données à caractère personnel, elle peut en faire part à :
            </Typography>
                    <ul>
                        <li>
                            <Typography>
                                Au D.P.D (Délégué à la Protection des Données) :
            </Typography>
                            <ul>
                                <li>
                                    <Typography>
                                        par mail : privacy@RESA.be
            </Typography></li>
                                <li>
                                    <Typography>
                                        par courrier : RESA S.A. Intercommunale – à l’attention du DPO Office – Rue Sainte-Marie 11 à 4000 Liège
            </Typography></li>
                            </ul>
                        </li>
                    </ul>
                    <Typography>
                        Dans ce cadre, votre demande doit nous parvenir datée et signée, accompagnée d’une copie/scan de votre carte d’identité

            Conformément à la règlementation, vous êtes en droit d’introduire une réclamation auprès de l’Autorité de contrôle compétente.
            </Typography>
                    <ul>
                        <li>
                            <Typography className={classes.title} component="h3">
                                Transfert des données en dehors de l’EEE
            </Typography></li>
                    </ul>
                    <Typography>
                        En cas de transferts internationaux émanant de l’EEE vers un pays tiers pour lequel la Commission européenne a rendu une décision d’adéquation reconnaissant à ce pays un niveau de protection des données personnelles équivalent à celui prévu par la législation de l’EEE, vos données personnelles seront transférées sur ce fondement.

            Pour des transferts vers des pays en dehors de l’EEE pour lesquels la Commission européenne n’a pas rendu de décision d’adéquation, nous nous appuyons soit sur une dérogation applicable à la situation (ex : en cas de paiement à l’international, le transfert est nécessaire à l’exécution du contrat), soit sur le fait que le destinataire des données a accepté de traiter les données à caractère personnel conformément aux « Clauses Contractuelles Types » établies par la Commission européenne pour les Responsables des données ou les sous-traitants.

            Pour obtenir une copie de ces textes ou savoir comment y accéder, vous pouvez adresser une demande écrite de la manière indiquée dans la Section 10.2.
            </Typography>
                    <ul>
                        <li>
                            <Typography className={classes.title} component="h3">
                                Violation de données à caractère personnel
            </Typography></li>
                    </ul>
                    <Typography className={classes.title} component="h3">
                        Mention des violations relatives aux données à caractère personnel
            </Typography>
                    <Typography>
                        Les utilisateurs autorisés doivent veiller, dans l’exercice de leur fonction, à éviter des incidents (volontaires ou non) qui peuvent porter atteinte à la vie privée des personnes concernées.

            En cas de violation de données à caractère personnel, des mesures adéquates sont prises le plus rapidement possible pour minimiser le risque de dommage pour les personnes concernées ainsi que pour RESA (atteinte à la réputation, sanctions imposées, …).

            Dans tous les cas, tous les utilisateurs autorisés, ainsi que toutes les autres personnes qui consultent, utilisent ou gèrent des informations de RESA doivent signaler immédiatement toute violation de la sécurité et les incidents en lien avec la sécurité des informations au D.P.D de sorte qu’une analyse puisse immédiatement être faite, les mesures nécessaires prises et pour savoir si la violation doit être signalée à l’Autorité de Protection des Données et/ou aux personnes concernées.

            Lorsque le signalement est réalisé par courriel, il est important que celui-ci soit envoyé au D.P.D (voir Section 10.2) et qu’il soit expressément indiqué dans l’objet du courriel qu’il s’agit d’un message avec urgence élevée à propos d’une possible violation en lien avec les données à caractère personnel.

            L’information doit contenir une description complète et détaillée de l’incident, en ce compris l’identité de la personne qui fait le signalement (nom, prénom, adresse, courriel (le cas échéant) et numéro de téléphone), de quel type d’incident il s’agit, et combien de personnes sont concernées.
            </Typography>
                    <Typography className={classes.title} component="h3">
                        Enquête et analyse des risques
            </Typography>
                    <Typography>
                        En principe, dans un délai de 24 heures après que l’incident ou la violation a été constaté par RESA ou signalé par un sous-traitant, un utilisateur autorisé, un destinataire, une personne concernée ou un tiers, une enquête sera entamée par RESA.

            L’enquête indiquera quelle est la nature de l’incident, le type de données visées et si spécifiquement des données à caractère personnel sont impactées (et dans l’affirmative, qui sont les personnes concernées et combien de données à caractère personnel sont touchées). L’enquête déterminera s’il s’agit d’une violation ou non des données à caractère personnel.

            S’il s’agit d’une violation, une analyse des risques sera effectuée pour savoir quelles sont (peuvent être) les conséquences possibles de la violation, et en particulier les impacts (possibles) pour les personnes concernées.

            RESA décidera ensuite, sur la base du degré de criticité de la violation, s’il y a ou non une obligation d’effectuer une notification à l’Autorité de Protection des Données et/ou à la personne concernée.
            </Typography>
                    <Typography className={classes.title} component="h3">
                        Documentation des violations
            </Typography>
                    <Typography>
                        Toutes les violations seront documentées dans un registre. Le registre détaillera la cause principale de l’incident et les facteurs contributifs, la chronologie des événements, les actions en réponse, les recommandations et les leçons apprises en vue d’identifier les domaines qui nécessitent une amélioration. Les changements recommandés à apporter aux systèmes, et procédures seront documentés et mis en place aussi vite que possible.

            Dans le cadre de sa mission de surveillance du respect de la règlementation en matière de protection des données,  le D.P.D examinera également les suites données au traitement de la violation consignée dans le rapport.
            </Typography>
                    <ul>
                        <li>
                            <Typography className={classes.title} component="h3">
                                Délégué à la protection des données (« D.P.D »)
            </Typography></li>
                    </ul>
                    <Typography>
                        RESA a désigné un délégué à la protection des données qui peut être contacté par courrier (DPO Office RESA – Rue Sainte-Marie 11 à 4000 Liège) ou par mail (privacy@RESA.be).
            </Typography>
                    <Typography>
                        Ce délégué à la protection des données est compétent :
            </Typography>
                    <ul>
                        <li>
                            <Typography>
                                Pour informer et conseiller RESA, ainsi que les membres du personnel, les administrateurs ainsi que la Direction quant aux obligations qui leur incombent en vertu de la Législation et règlementation en matière de protection des données ;
            </Typography></li>
                        <li>
                            <Typography>
                                Pour contrôler le respect de la Législation et règlementation en matière de protection des données et de la politique de traitement et de protection des données à caractère personnel dans le cadre des activités de RESA tels que repris dans la présente politique, y compris en ce qui concerne la répartition des responsabilités, la sensibilisation et la formation des personnes participant aux opérations de traitement, et les audits s’y rapportant ;
            </Typography></li>
                        <li>
                            <Typography>
                                Pour dispenser des conseils, sur demande, en ce qui concerne l’analyse d’impact relative à la protection des données à caractère personnel et d’en vérifier l’exécution;
            </Typography></li>
                        <li>
                            <Typography>
                                Pour coopérer avec l’Autorité de Protection des Données ;
            </Typography></li>
                        <li>
                            <Typography>
                                Pour faire office de point de contact pour :
            </Typography>
                            <ul>
                                <li>
                                    <Typography>
                                        Les personnes concernées, qui peuvent contacter le délégué à la protection des données pour toutes les questions liées au traitement de leurs données à caractère personnel et à l’exercice de leurs droits ;
            </Typography></li>
                                <li>
                                    <Typography>
                                        Toute personne qui constate un incident ou une violation en lien avec le traitement des données à caractère personnel dans le cadre des activités de RESA et qui doivent, entre autres, en informer le Délégué à la protection des données ;
            </Typography></li>
                                <li>
                                    <Typography>
                                        L’Autorité de Protection des Données en ce qui concerne les questions liées aux traitements de données à caractère personnel ;
            </Typography></li>
                            </ul>
                        </li>
                        <li>
                            <Typography>
                                Pour tenir dûment compte du risque associé aux opérations de traitement, compte tenu de la nature, de la portée, du contexte et des finalités du traitement ;
            </Typography></li>
                        <li>
                            <Typography>
                                Pour toute autre mission ou tâche qu’il se verrait confier.
            </Typography></li>
                    </ul>
                    <br />
                    <ul>
                        <li>
                            <Typography className={classes.title} component="h3">
                                Comment prendre connaissance de cette politique et de ses modifications ?
            </Typography></li>
                    </ul>
                    <Typography>
                        Dans un monde en constante évolution technologique, nous actualiserons régulièrement la Politique « Vie Privée ».

            Nous vous invitons à prendre connaissance de la dernière version de ce document sur notre site et nous vous informerons de toute modification substantielle par le biais de nos sites ou par nos modes de communications habituels.
            </Typography>
                    <ul>
                        <li>
                            <Typography className={classes.title} component="h3">
                                Comment nous contacter ?
            </Typography></li>
                    </ul>
                    <Typography>
                        Si vous avez des questions concernant l’utilisation de vos données personnelles visée par la présente politique, vous pouvez contacter notre délégué à la protection des données (D.P.D) par courrier adressé à DPO Office RESA – Rue Sainte-Marie 11 à 4000 Liège ou par e-mail à l’adresse <ExternalLink href="mailto:privacy@resa.be" label="privacy@resa.be" />.

            En ce qui concerne les Membres de notre personnel, ceux-ci peuvent également consulter la « Politique Vie Privée R.H » sur l’intranet.
            </Typography>
                    <br />
                    <br />
                    <Typography>
                        La présente Politique « Vie Privée » est applicable à dater du 25 mai 2018.
            </Typography>
                </div>
                <Divider />
                {/** VERSION ALLEMANDE */}
                <div className={classes.policy}>
                    <ul>
                        <li>
                            <Typography component="h3" className={classes.title}>Zielsetzung</Typography>
                        </li>
                    </ul>
                    <Typography>
                        Das vorliegende Dokument beinhaltet die von RESA im Rahmen seiner Tätigkeit angewandte Datenschutzpolitik.

        Der Schutz Ihrer Privatsphäre und Ihrer personenbezogenen Daten ist für RESA von entscheidender Bedeutung.

        Dieses Dokument zur Datenschutzpolitik wurde verfasst, um die Einhaltung der europäischen Verordnung 2016/679 vom 27. April 2016 zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten, zum freien Datenverkehr und zur Aufhebung der Richtlinie 95/46/EG (Datenschutz-Grundverordnung, DSGVO), zu gewährleisten.

        Das vorliegende Dokument hat zum Ziel, Sie über unsere Datenschutzpolitik lückenlos zu informieren. Es erklärt, we wir Ihre personenbezogenen Daten erheben, verwenden und speichern.
                </Typography>
                    <ul>
                        <li>
                            <Typography component="h3" className={classes.title}>Welche Tragweite hat diese Politik ?</Typography>
                        </li>
                    </ul>
                    <Typography component="h3" className={classes.title}>Was beinhaltet die „Verarbeitung Ihrer Daten“ und wer ist dafür verantwortlich ?</Typography>
                    <Typography>
                        Wir erheben und verwenden ausschließlich die personenbezogenen Daten, die wir im Rahmen unserer Tätigkeiten benötigen, um Ihnen hochwertige Dienstleistungen anbieten zu können.

        Die Firma RESA S.A Interkommunale mit Geschäftssitz zu 4000 Lüttich, Rue Sainte-Marie 11, haftet für die Verarbeitung der von ihr verwendeten personenbezogenen Daten.

        Folglich sind wir zugleich Ihr Ansprechpartner und derjenige der per Gesetz vom 3. Dezember 2017 ins Leben gerufenen Datenschutzbehörde für alle Fragen zur Verwendung Ihrer Daten durch unser Unternehmen.

        Für gewisse Dienstleistungen greifen wir auf die Mitarbeit spezialisierter Drittfirmen zurück, die als Unterauftragnehmer für Sie tätig werden. Diese müssen sich an unsere Weisungen halten und unsere Datenschutzpolitik respektieren. In anderen Fällen sind diese Drittfirmen auch für die Datenverarbeitung mitverantwortlich und müssen ihrerseits alle Rechtsvorschriften auf diesem Gebiet einhalten.

        Wir vergewissern uns, dass die Unterauftragnehmer nur die Daten erhalten, die zur Ausführung des von ihnen erfüllten Teil des Auftrags zwingend notwendig sind.
                </Typography>
                    <ul>
                        <li>
                            <Typography component="h3" className={classes.title}>
                                Welche Daten sind von unserer Datenschutzpolitik abgedeckt?
                </Typography></li>
                    </ul>
                    <Typography>Die von unserer Datenschutzpolitik abgedeckten Daten sind die personenbezogenen Daten natürlicher Personen, d.h. Daten, anhand derer sich eine natürliche Person direkt oder indirekt identifizieren lässt.

Im Rahmen Ihrer Beziehungen zu RESA und Ihrer Interaktionen mit uns können wir uns veranlasst sehen, verschiedene personenbezogene Daten zu sammeln, etwa:</Typography>
                    <ul>
                        <li>
                            <Typography> Ihre Identifizierungs- und Kontaktangaben (Beispiele: Titel, Name, Anschrift, Geburtsdatum und -ort, Nationalregisternummer, Kontonummer, Telefonnummer, E-Mail-Adresse, EAN-Code, Beruf);</Typography>
                        </li>
                        <li><Typography>Ihre familiäre Situation (Beispiele: Zivilstand, Zahl der Kinder);</Typography></li>
                        <li><Typography>Bank-, Finanz- und Transaktionsdaten (Beispiele: Bankverbindungen mit Kontonummern, Daten zu Überweisungen einschließlich der Mitteilung und ganz allgemein alle Daten, die bei Ihren Banküberweisungen gespeichert werden);</Typography></li>
                        <li><Typography>Daten bezüglich Ihrer Verhaltensweisen und Gewohnheiten bei der Nutzung unserer Kanäle (z.B. unserer Webseite);</Typography></li>
                    </ul>
                    <Typography>Wir verarbeiten niemals Daten im Zusammenhang mit Ihrer Rasse oder  Ihrer ethnischen Herkunft, Ihren politischen Standpunkten, Ihrer Religion, Ihren philosophischen Überzeugungen oder Ihrer Gewerkschaftszugehörigkeit, noch Ihre genetischen Daten oder Daten, die Ihr Sexualleben oder Ihre sexuelle Orientierung betreffen.</Typography>
                    <ul>
                        <li>
                            <Typography component="h3" className={classes.title}>
                                Unsere Leitsätze bei der Verarbeitung personenbezogener Daten
                </Typography></li>
                    </ul>
                    <Typography>RESA hält sich bei der Verarbeitung personenbezogener Daten im Rahmen der Verwaltung und Ausführung seines Auftrags unter anderem an folgende Leitsätze:</Typography>
                    <ul>
                        <li><Typography>Rechtmäßige Datenverarbeitung: RESA verarbeitet personenbezogene Daten im Rahmen seiner Tätigkeit auf eine rechtmäßige Art und Weise;</Typography>
                        </li>
                        <li><Typography>Festgelegte Zwecke und Einschränkung der Zwecke: RESA erhebt und verarbeitet personenbezogene Daten zu den nachstehend festgelegten rechtmäßigen Zwecken;</Typography>
                        </li>
                        <li><Typography>Minimierung der Datenverarbeitung: RESA begrenzt die Verarbeitung personenbezogener Daten auf das Notwendige im Rahmen seiner Tätigkeiten;</Typography>
                        </li>
                        <li><Typography>Richtigkeit der personenbezogenen Daten: RESA ergreift alle angemessenen Maßnahmen um zu gewährleisten, dass die personenbezogenen Daten korrekt sind und im gegenteiligen Fall umgehend berichtigt und/oder gelöscht werden.</Typography>
                        </li>
                        <li><Typography>Einschränkung der Verarbeitung und der Speicherung: RESA verarbeitet und speichert die personenbezogenen Daten nicht länger, als dies zur Ausführung seiner Tätigkeiten notwendig ist.</Typography>
                        </li>
                        <li> <Typography>Sicherheitsmaßnahmen: RESA ergreift die nötigen technischen und/oder organisatorischen Maßnahmen, um die Sicherheit der personenbezogenen Daten zu gewährleisten.</Typography>
                        </li>
                    </ul>
                    <br />
                    <ul><li>
                        <Typography component="h3" className={classes.title}>
                            Wann werden Ihre personenbezogenen Daten erhoben?
                </Typography></li></ul>
                    <Typography>Die von uns zur Überprüfung oder Ergänzung unserer Datenbanken gesammelten Daten können  entweder direkt über Sie oder über die folgenden Quellen erhoben werden:</Typography>
                    <ul>
                        <li>
                            <Typography>Veröffentlichungen / von Behörden öffentlich zugänglich gemachte Datenbanken (z.B.: das Belgische Staatsblatt);</Typography></li>
                        <li>
                            <Typography>Unsere Kundenunternehmen oder Dienstleistungsanbieter;</Typography></li>
                        <li>
                            <Typography>Webseiten / soziale Netzwerke, die von Ihnen veröffentlichte Informationen enthalten (z.B. Ihre Webseite oder ein von Ihnen genutzes soziales Netzwerk);</Typography></li>
                        <li>
                            <Typography>Datenbanken, die von Dritten öffentlich gemacht wurden.</Typography></li>
                    </ul>
                    <Typography>Einige Ihrer Daten können von RESA auch dann gesammelt werden, wenn:</Typography>
                    <ul>
                        <li>
                            <Typography>Sie zum Nutzer des Verteilungsnetzes, Firmenkunden, Lieferanten oder Dienstleistungsanbieter werden;</Typography></li>
                        <li>
                            <Typography>Sie sich anmelden, um unsere Online-Dienste zu verwenden (bei jeder Identifizierung oder Nutzung);</Typography></li>
                        <li>
                            <Typography>Sie die Formulare ausfüllen, die wir Ihnen unterbreiten;</Typography></li>
                        <li>
                            <Typography>Sie infolge der Unterzeichnung eines Vertrages unsere Dienstleistungen in Anspruch nehmen;</Typography></li>
                        <li>
                            <Typography>Sie uns über die verschiedenen Kanäle, die wir Ihnen zur Verfügung stellen, kontaktieren;</Typography></li>
                        <li>
                            <Typography>Ihre Daten uns von ermächtigten Dritten oder professionellen Datenanbietern übermittelt werden;</Typography></li>
                        <li>
                            <Typography>Sie von unseren Überwachungskameras in unseren Räumlichkeiten/Gebäuden oder in deren Umfeld gefilmt werden.</Typography></li>
                    </ul>
                    <br />
                    <Typography>Die Bilder werden nur zu dem Zweck aufgezeichnet, die Sicherheit der Personen und Güter zu gewährleisten und Missbrauch, Betrug und andere Verstöße gegen unsere Kunden und/oder unser Personal zu vermeiden (auf die Anwesenheit der Kameras wird durch Aufkleber mit unseren Kontaktangaben hingewiesen).</Typography>
                    <br />
                    <ul>
                        <li>
                            <Typography component="h3" className={classes.title}>
                                Auf welcher Grundlage und wozu nutzen wir Ihre personenbezogenen Daten?
</Typography>
                        </li>
                    </ul>
                    <Typography>Wir verarbeiten Ihre personenbezogenen Daten zu unterschiedlichen Zwecken. Bei jedem Vorgang werden nur die jeweils zweckdienlichen Daten verarbeitet.

Im Allgemeinen verwenden wir Ihre personenbezogenen Daten:</Typography>
                    <ul>
                        <li>
                            <Typography>Im Rahmen der Ausführung eines Vertrages oder des Ergreifens vorvertraglicher Maßnahmen;</Typography>
                        </li>
                        <li>
                            <Typography>Zur Erfüllung der Rechts- und Verwaltungsvorschriften, denen wir unterworfen sind;</Typography>
                        </li>
                        <li>
                            <Typography>Aus Gründen, die dem legitimen Recht der Firma entsprechen (Vgl. nachstehende Abbildungen). Wenn wir Verarbeitungen dieser Art vornehmen, achten wir darauf, zwischen diesem legitimen Interesse und der Achtung Ihrer Privatsphäre ein Gleichgewicht zu wahren;</Typography>
                        </li>
                        <li>
                            <Typography>Wenn wir Ihre Zustimmung erhalten haben.</Typography>
                        </li>
                    </ul>
                    <Typography>Die personenbezogenen Daten werden von RESA zu folgenden Zwecken verarbeitet, ohne sich darauf zu beschränken:</Typography>
                    <ul>
                        <li>
                            <Typography>Ihnen Informationen zu unseren Dienstleistungen zu liefern;</Typography></li>
                        <li>
                            <Typography>Sie zu unterstützen, auf Ihre Anfragen einzugehen oder eine Beschwerde zu bearbeiten;</Typography></li>
                        <li>
                            <Typography>Eine gute Ausführung der getroffenen Vereinbarungen zu ermöglichen;</Typography></li>
                        <li>
                            <Typography>Die Haushalts- und Rechnungsführung von RESA zu gewährleisten;</Typography></li>
                        <li>
                            <Typography>Eine gute Verwaltung der Netzbenutzer, der Firmenkunden, der Lieferanten und unserer damit verbundenen Netze und Anlagen zu gewährleisten;</Typography></li>
                        <li>
                            <Typography>Die gesetzlichen Bestimmungen zu erfüllen und in diesem Zusammenhang unter anderem die Anfragen der CWaPund der entsprechend ermächtigten staatlichen Behörden oder Justizbehörden zu beantworten;</Typography></li>
                        <li>
                            <Typography>Eine Datenverarbeitung zu gewährleisten, die zur Erfüllung unseres Auftrags der Daseinsvorsorge oder zur Teilnahme an der Ausübung öffentlicher Gewalt erforderlich ist: Hierbei geht es vor allem darum, für den Anschluss jedes antragstellenden Endkunden an das Netz zu sorgen, die Zählerstände und den Energieverbrauch zu validieren und den Energielieferanten zu übermitteln; die Elektritzitäts- und  Gasströme und die damit verbundenen Zähler zu verwalten, sowie alle angemessenen Maßnahmen im Sinne einer rationellen Energieverwendung zu ergreifen…</Typography></li>
                        <li>  <Typography>Unseren öffentlich-rechtlichen Auftrag zu erfüllen, indem RESA geschützten Kunden Energie zum Sozialtarif liefert oder in bestimmten, von der Regierung nach Stellungnahme der CWaPE vorgesehenen Fällen, vorübergehend Endkunden beliefert, die zeitweilig nicht über einen Liefervertrag verfügen.</Typography>
                        </li>
                        <li>               <Typography>Missbrauch und Betrug aufzuspüren und zu verhindern: Wir verarbeiten und verwalten Kontakt- und Sicherheitsangaben (Kartenleser, Passwort…) um die Sicherheit unserer Netze und Anlagen zu validieren, nachzuverfolgen und zu gewährleisten;</Typography>
                        </li>
                        <li> <Typography>Die Erbringung von Dienstleistungen über die Hinzuziehung von Unterauftragnehmern sicherzustellen;</Typography>
                        </li>
                        <li><Typography>Eine Nachverfolgung unserer Aktivitäten (Ermittlung der Eingriffe, der Zahl der Termine, der Zahl der Anrufe, der Besuche auf unserer Webseite…);</Typography>
                        </li>
                        <li>  <Typography>Verbesserung der Qualität des individuellen DIenstes an unseren Kunden;</Typography>
                        </li>
                        <li>
                            <Typography>Sicherung unserer Räumlichkeiten und Infrastruktur, sowie der Personen, die sich darin aufhalten.</Typography></li>
                    </ul>
                    <br />
                    <ul>
                        <li>                <Typography component="h3" className={classes.title}>
                            Wer hat Zugang zu Ihren Daten und an wen werden sie weitergegeben?
                </Typography></li>
                    </ul>
                    <Typography>Nur autorisierte Benutzer haben Zugang zu Ihren personenbezogenen Daten, um die vorangehend beschriebenen Zwecke zu erfüllen. Unter einem autorisierten Benutzer verstehen wir jede Person, die im Rahmen der Ausübung ihrer Funktion bei RESA dazu ermächtigt ist, auf der Grundlage der RESA-Weisungen personenbezogene Daten zu verarbeiten.

Zur Erfüllung der vorgenannten Zwecke kann RESA Ihre personenbezogenen Daten an folgende Stellen übermitteln:</Typography>
                    <ul>
                        <li>
                            <Typography>Einen Aktuar;</Typography></li>
                        <li>
                            <Typography>Einen externen Auditoren;</Typography></li>
                        <li>
                            <Typography>Einen anerkannten Wirtschaftsprüfer;</Typography></li>
                        <li>
                            <Typography>Einen Rechtsberater;</Typography></li>
                        <li>
                            <Typography>Einen Finanzberater;</Typography></li>
                        <li>
                            <Typography>Einen anderen Fachmann und/oder Dienstleistungserbringer/Berater;</Typography></li>
                        <li>
                            <Typography>Ein Sozialsekretariat, ein Bankinstitut, eine Versicherungsgesellschaft, einen Fonds;</Typography></li>
                        <li> <Typography>Unsere Call Center;</Typography></li>
                        <li><Typography>Dienstleistungsanbieter (z.B. Unterauftragnehmer, Pannendienste…);</Typography></li>
                        <li><Typography>Einen Energielieferanten;</Typography></li>
                        <li><Typography>IT-Unternehmen oder Dienstleistungserbringer für Software-Programme und die Speicherung elektronischer Daten (Server usw.);</Typography></li>
                        <li><Typography>Justiz- und Verwaltungsbehörden oder Polizeidienste;</Typography></li>
                        <li> <Typography>Kontrollbehörden.</Typography></li>
                    </ul>
                    <br />
                    <ul>
                        <li>
                            <Typography className={classes.title} component="h3">
                                Wie lange bleiben Ihre Daten bei uns gespeichert?
</Typography>
                        </li>
                    </ul>
                    <Typography>Wir speichern Ihre personenbezogenen Daten so lange wie nötig unter Einhaltung der geltenden Rechts- und Verwaltungsvorschriften oder für die notwendige Dauer angesichts betrieblicher Sachzwänge wie eine saubere Buchführung, ein effizientes Management und Antworten auf Rechtsansprüche oder Anfragen des Regulierers.

    Die Daten der Netzbenutzer und/oder Firmenkunden oder geschützten Kunden werden für die Dauer der Rechtsbeziehungen gespeichert, auf die sie sich beziehen, und darüber hinaus für einen grundsätzlichen Zeitraum von zehn Jahren.

Manche Daten bleiben über einen längeren Zeitraum archiviert, um unsere gesetzlichen Verpflichtungen zu erfüllen und zu Beweiszwecken, insbesondere um Ihre Rechte und die unserer Gesellschaft zu wahren. Diese archivierten Daten sind nur als Beweismittel vor Gericht, bei einer Kontrolle durch eine anerkannte Behörde (z.B. die Steuerbehörde) oder zur Beibringung von Unterlagen vor Justiz- und Verwaltungsbehörden oder Polizeidiensten zugänglich.</Typography>
                    <ul>
                        <li>
                            <Typography className={classes.title} component="h3">
                                Sicherheit und Vertraulichkeit
</Typography>
                        </li>
                    </ul>
                    <Typography>RESA verpflichtet sich, die nötigen und angemessenen technischen, physischen und organisatorischen Maßnahmen zu ergreifen, um die personenbezogenen Daten vor unbefugtem Zugriff, illegaler und unerlaubter Verarbeitung, Verlust oder versehentlichen Schäden, sowie unerlaubter Zerstörung zu schützen. Diese Maßnahmen werden in regelmäßigen Abständen evaluiert und bei Bedarf aktualisiert, um einen maximalen Schutz der personenbezogenen Daten zu garantieren.

Im Fall einer Zuwiderhandlung oder eines Datenlecks wie vorangehend beschrieben, ergreift RESA die notwendigen/angemessenen Maßnahmen, um das Ausmaß und die Folgen des Zwischenfalls festzustellen, diesem so schnell wie möglich ein Ende zu bereiten und seine Auswirkungen für die betroffenen Personen gegebenenfalls auf ein Mindestmaß zu beschränken.</Typography>
                    <ul>
                        <li>
                            <Typography className={classes.title} component="h3">
                                Was sind Ihre Rechte und wie können Sie diese geltend machen?
</Typography>
                        </li>
                    </ul>
                    <br />
                    <ul>
                        <li>
                            <Typography className={classes.title} component="h3">
                                Rechte der betroffenen Personen
</Typography>
                        </li>
                    </ul>
                    <Typography>Dem geltenden Recht entsprechend, verfügen Sie über verschiedene Rechte:</Typography>
                    <ul>
                        <li><Typography>Das Recht, Zugang zu Ihren personenbezogenen Daten zu verlangen (A)</Typography></li>
                        <li><Typography>Das Recht auf Berichtigung (A)</Typography></li>
                        <li><Typography>Das Recht auf Löschung Ihrer Daten (A)</Typography></li>
                        <li><Typography>Das Recht, Einspruch gegen die Verarbeitung zu erheben (B)</Typography></li>
                        <li><Typography>Das Recht, Ihre Zustimmung zu widerrufen (B)</Typography></li>
                        <li><Typography>Das Recht, eine Einschränkung der Verarbeitung zu fordern (B)</Typography></li>
                        <li><Typography>Das Recht auf Portabilität Ihrer Daten (C)</Typography></li>
                    </ul>
                    <br />
                    <Typography className={classes.title} component="h3">
                        Recht auf Zugang, Berichtigung und Löschung
</Typography>
                    <Typography>Jede Betroffene Person hat das Recht, eine Zugriffsanfrage zu stellen. Wenn eine betroffene Person dieses Recht ausübt, ist RESA verpflichtet, dieser Person diesbezügliche Auskünfte zu erteilen, einschließlich:</Typography>
                    <ul>
                        <li><Typography>Einer Beschreibung und einer Kopie der personenbezogenen Daten;</Typography></li>
                        <li><Typography>Einer Unterrichtung der betroffenen Person über die Gründe, warum RESA diese Daten verarbeitet.</Typography></li>
                    </ul>
                    <Typography>
                        Wenn Daten fehlerhaft oder unvollständig sind, kann die betroffene Person um deren Berichtigung ersuchen.

            Unter gewissen Umständen kann die betroffene Person laut Datenschutzgesetz um die Löschung bestimmter sie betreffender personenbezogener Daten ersuchen, insbesondere wenn diese personenbezogenen Daten zu den Zwecken, für die sie ursprünglich erhoben oder verarbeitet wurden, nicht mehr benötigt werden. RESA kann die Löschung dieser Daten jedoch ablehnen, wenn es diese benötigt, um einen Rechtsanspruch vor Gericht anzumelden, geltend zu machen oder zu beweisen.

            In dem Bestreben, Ihre Daten jederzeit aktuell zu halten, bitten wir Sie, uns über jegliche Änderung umgehend zu unterrichten (z.B. Änderung des Zivilstands oder des Wohnsitzes).
            </Typography>
                    <Typography component="h3" className={classes.title}>
                        Recht auf Einspruch gegen die Verarbeitung ihrer Daten, Recht auf eine eingeschränkte Verarbeitung und Recht auf Widerrufung Ihrer Einwilligung
            </Typography>
                    <Typography>
                        Sie haben das Recht, gegen bestimmte Verarbeitungen ihrer personenbezogenen Daten, die wir durchführen möchten, Einspruch zu erheben. Sie können auch um eine Einschränkung der Verarbeitung Ihrer Daten ersuchen.

            Allerdings dürfen Sie dieses Recht nur unter gewissen Bedingungen ausüben:
            </Typography>
                    <ul>
                        <li><Typography>Ihre Anfrage muss datiert und unterzeichnet sein;</Typography></li>
                        <li><Typography>Sie müssen gewichtige und legitime Gründe im Zusammenhang mit Ihrer besonderen Situation haben, um sich der Datenverarbeitung zu widersetzen. Im Fall des begründeten Einspruchs dürfen Ihre Daten der betreffenden Verarbeitung nicht mehr unterzogen werden.</Typography></li>
                    </ul>
                    <Typography>
                        Hingegen können Sie gegen die Ausführung eines mit Ihnen abgeschlossenen Vertrages oder gegen die Durchführung der auf Ihr Ersuchen ergriffenen vorvertraglichen Maßnahmen keinen Einspruch erheben; auch gegen jegliche Maßnahmen zur Einhaltung von Rechts- oder Verwaltungsvorschriften, denen wir unterliegen, ist kein Einspruch möglich.

            Wenn Sie der Verarbeitung Ihrer personenbezogenen Daten zugestimmt haben, so haben Sie jederzeit das Recht, diese Zustimmung zu widerrufen.
            </Typography>
                    <Typography component="h3" className={classes.title}>
                        Das Recht auf Portabilität
            </Typography>
                    <Typography>Wenn notwendig, und soweit anwendbar, kann der Betroffene darum bitten, bestimmte personenbezogene Daten, die er RESA im Rahmen des Managements und der Durchführung seiner Aktivitäten zur Verfügung gestellt hat, zurück zu erhalten und diese an einen anderen Verarbeitungsverantwortlichen zu übertragen. Sofern dies technisch möglich ist, kann der Betroffene RESA bitten, diese Daten direkt an einen anderen Verarbeitungsverantwortlichen zu übertragen.</Typography>
                    <ul>
                        <li>
                            <Typography component="h3" className={classes.title}>An wen müssen Sie sich wenden?</Typography>
                        </li>
                    </ul>
                    <Typography>Wenn der Betroffene in Bezug auf seine personenbezogenen Daten seine Rechte geltend machen möchte, wendet er sich an:</Typography>
                    <ul>
                        <li>
                            <Typography>Den Datenschutzbeauftragten (DPO): </Typography>
                            <ul>
                                <li><Typography>Per Mail: privacy@RESA.be</Typography></li>
                                <li><Typography>Per Post: RESA S.A Interkommunale – DPO Office – Rue Sainte-Marie 11 in 4000 Lüttich</Typography></li>
                            </ul>
                        </li>
                    </ul>
                    <Typography>
                        Ihre Anfrage muss datiert und unterschrieben sein und eine Kopie/ein Scan Ihres Personalausweises muss ihr beigelegt sein.

             Per Gesetzgebung haben Sie das Recht, bei der zuständigen Kontrollbehörde eine Beschwerde einzureichen.
           </Typography>
                    <ul>
                        <li>
                            <Typography component="h3" className={classes.title}>Datenübertragung außerhalb des EWR</Typography>
                        </li>
                    </ul>
                    <Typography>
                        Im Fall internationaler Transfers aus dem EWR in ein Drittland, für das die Europäische Kommission eine Angemessenheitsentscheidung erlassen hat, mit der anerkannt wurde, dass dieses Land einen gleichwertigen Schutz der personenbezogenen Daten bietet wie im EWR per Gesetz vorgeschrieben, werden Ihre personenbezogenen Daten auf dieser Grundlage übertragen.

        Bei Transfers aus dem EWR in ein Drittland, für das die Europäische Kommission keine Angemessenheitsentscheidung erlassen hat, stützen wir uns auf eine Ausnahmeregelung, die in diesem Fall Anwendung findet (z.B. ist bei einer internationalen Zahlungsanweisung der Transfer zur Ausführung des Vertrages vonnöten), d.h. auf die Tatsache, dass der Empfänger der Daten akzeptiert hat, personenbezogene Daten gemäß des von der Europäischen Kommission festgelegten neuen „Standardvertragsklauseltyps“ für Datenmanager oder Unterauftragnehmer zu verarbeiten.

        Um eine Kopie dieser Texte zu erhalten oder zu erfahren, wie Sie darauf zugreifen können, haben Sie die Möglichkeit, einen schriftlichen Antrag zu stellen wie in Abschnitt 10.2 beschrieben.
                </Typography>
                    <ul>
                        <li>
                            <Typography component="h3" className={classes.title}>Verletzung des Schutzes personenbezogener Daten</Typography>
                        </li>
                    </ul>
                    <Typography component="h3" className={classes.title}>Erwähnung der Verletzung des Schutzes personenbezogener Daten</Typography>
                    <Typography>
                        Die autorisierten Nutzer müssen bei der Ausübung ihrer Funktion darauf achten, Zwischenfälle (egal ob bewusst herbeigeführt oder nicht) zu vermeiden, welche die Privatsphäre der betroffenen Personen verletzen können.

        Bei einer Verletzung des Schutzes personenbezogener Daten werden schnellstmöglich geeignete Maßnahmen ergriffen, um das Risiko eines Schadens für die betroffenen Personen, sowie für RESA (Image-Schaden, Verfügung von Sanktionen…) zu minimieren.

        In jedem Fall müssen alle autorisierten Benuzter, sowie alle anderen Personen, die Informationen von RESA einsehen, verwenden oder verwalten, jegliche Verletzung des Schutzes personenbezogener Daten und Zwischenfälle im Zusammenhang mit der Sicherheit der Informationen unverzüglich dem DPO melden, so dass umgehend eine Analyse durchgeführt und die notwendigen Maßnahmen ergriffen werden können, und um zu entscheiden, ob die Verletzung der Datenschutzbehörde und/oder den betroffenen Personen gemeldet werden muss oder nicht.

        Wenn die Meldung per E-Mail erfolgt, ist es wichtig, diese an den DPO zu senden (vgl. Abschnitt 10.2) und in der Betreffzeile ausdrücklich zu vermerken, dass es sich um eine Nachricht von hoher Dringlichkeit über eine mögliche Verletzung des Schutzes personenbezogener Daten handelt.

        Die Information muss eine vollständige und detaillierte Beschreibung des Zwischenfalls enthalten, einschließlich der Identität der Person, die Meldung erstattet (Name, Vorname, Anschrift, gegebenenfalls E-Mail-Adresse und Telefonnummer), der Art des Zwischenfalls und der Zahl der betroffenen Personen.
                </Typography>
                    <Typography component="h3" className={classes.title}>Untersuchung und Risikoanalyse</Typography>
                    <Typography>
                        Im Prinzip leitet RESA binnen 24 Stunden nachdem die Firma selbst einen Zwischenfall oder eine Verletzung der Privatsphäre festgestellt oder diese von einem Unterauftragnehmer, einem autorisierten Benuzter, einem Empfänger, einer betroffenen Person oder einem Dritten gemeldet bekommen hat, eine Untersuchung der Angelegenheit ein.

        Aus dieser Untersuchung ergibt sich die Art des Zwischenfalls, der anvisierte Datentyp und ob speziell personenbezogene Daten betroffen sind (und falls ja, wer die betroffenen Personen sind und um welche Menge an personenbezogenen Daten es dabei geht). Die Untersuchung zeigt, ob eine Verletzung des Schutzes personenbezogener Daten vorliegt oder nicht.

        Wenn es sich um eine Verletzung des Schutzes personenbezogener Daten handelt, wird eine Risikoanalyse durchgeführt, um festzustellen, welche die möglichen Folgen des Zwischenfalls sind oder sein können und vor allem, welche (möglichen) Auswirkungen es für die betroffenen Personen gibt.

        In der Folge entscheidet RESA je nach Schwere des Verstoßes gegen die Datenschutzbestimmungen, ob eine Meldung an die Datenschutzbehörde und/oder die betroffenen Person erfolgen muss.
                </Typography>
                    <Typography component="h3" className={classes.title}>Dokumentierung der Datenschutzverletzungen</Typography>
                    <Typography>
                        Alle Verletzungen des Datenschutzes werden in einem Verzeichnis dokumentiert. In diesem Verzeichnis werden die wichtigste Ursache des Zwischenfalls und die beitragenden Faktoren, die Abfolge der Ereignisse, die als Reaktion darauf ergriffenen Maßnahmen, die Empfehlungen und die gezogenen Lehren detailliert vermerkt, um die Bereiche zu identifzieren, die einer Verbesserung bedürfen. Die Empfehlungen zur Abänderung der Systeme und Verfahren werden dokumentiert und möglichst rasch umgesetzt.

        Im Rahmen seines Auftrags der Überwachung, ob alle Datenschutzvorschriften eingehalten werden, prüft der DPO auch, welche Schritte infolge der im Bericht festgehaltenen Verletzung des Schutzes personenbezogener Daten zur Abhilfe unternommen werden.
                </Typography>
                    <ul>
                        <li>
                            <Typography component="h3" className={classes.title}>Der Datenschutzbeauftragte („DPO“)</Typography>
                        </li>
                    </ul>
                    <Typography>
                        RESA hat einen Datenschutzbeauftragten ernannt, der per Post (DPO Office RESA – Rue Sainte-Marie 11 in 4000 Lüttich) oder per Mail (privacy@RESA.be) kontaktiert werden kann.

        Dieser Datenschutzbeauftragte ist für folgende Aufgaben zuständig:
                </Typography>
                    <ul>
                        <li><Typography>Information und Beratung von RESA, sowie der Mitarbeiter, des Verwaltungsrats und der Geschäftsleitung über ihre Verpflichtungen im Rahmen der Rechts- und Verwaltungsvorschriften im Datenschutzbereich;</Typography></li>
                        <li><Typography>Kontrolle der Einhaltung der Rechts- und Verwaltungsvorschriften in Sachen Datenschutz, sowie der Verarbeitung und des Schutzes personenbezogener Daten im Rahmen der RESA-Tätigkeiten wie in der vorliegenden Datenschutzerklärung beschrieben, zu kontrollieren, einschließlich der Verteilung der Zuständigkeiten, sowie der Sensibilisierung und Schulung aller Personen, die an den Datenverarbeitungsvorgängen und den damit verbundenen Audits mitwirken;</Typography></li>
                        <li><Typography>Auf Anfrage, Beratung bezüglich der Folgenabschätzung zum Schutz personenbezogener Daten und Überprüfung ihrer Durchführung;</Typography></li>
                        <li><Typography>Zusammenarbeit mit der Datenschutzbehörde;</Typography></li>
                        <li>
                            <Typography>Ansprechpartner für: </Typography>
                            <ul>
                                <li><Typography>Die betroffenen Personen, die den Datenschutzbeauftragten für jegliche Fragen in Verbindung mit der Verarbeitung ihrer personenbezogenen Daten und mit der Ausübung ihrer Rechte kontaktieren können;</Typography></li>
                                <li><Typography>Jegliche Person, die einen Zwischenfall oder eine Verletzung des Datenschutzes bei der Verarbeitung personenbezogener Daten im Rahmen der RESA-Tätigkeiten feststellt, und die unter anderem den Datenschutzbeauftragten darüber informieren muss;</Typography></li>
                                <li><Typography>Die Datenschutzbehörde in Bezug auf alle Fragen zur Verarbeitung personenbezogener Daten;</Typography></li>
                            </ul>
                        </li>
                        <li>Eine angemessene Berücksichtigung der Risiken in Verbindung mit der Verarbeitung, angesichts der Art, der Tragweite, des Kontextes und des Zwecks der Verarbeitung;</li>
                        <li>Für jeglichen anderen Auftrag oder jegliche Aufgabe, mit denen man ihn betrauen würde.</li>
                    </ul>
                    <br />
                    <ul>
                        <li>
                            <Typography component="h3" className={classes.title}>Wie kann man über die RESA-Datenschutzpolitik und ihre Änderungen informiert bleiben?</Typography>
                        </li>
                    </ul>
                    <Typography>
                        In einer Welt, die sich auf technologischer Ebene ständig weiterentwickelt, werden wir diese Datenschutzerklärung regelmäßig auf den neuesten Stand bringen.

        Wir laden Sie ein, die jeweils aktuelle Fassung des vorliegenden Dokuments auf unserer Webseite zur Kenntnis zu nehmen und werden sie auf diesem Wege, sowie über all unsere üblichen Kommunikationskanäle über jegliche substanzielle Änderung auf dem Laufenden halten.
                </Typography>
                    <ul>
                        <li>
                            <Typography component="h3" className={classes.title}>Wie können Sie sich mit uns in Verbindung setzen?</Typography>
                        </li>
                    </ul>
                    <Typography>
                        Wenn Sie Fragen zur Verwendung Ihrer personenbezogenen Daten haben, von denen in der vorliegenden Erklärung die Rede ist, kontaktieren Sie unseren Datenschutzbeauftragten (DPO) per Post an DPO Office RESA – Rue Sainte-Marie 11 in 4000 Lüttich oder per E-Mail unter der Adresse <ExternalLink href="mailto:privacy@resa.be" label="privacy@resa.be" />.
                </Typography>
                </div>
            </div>
        </React.Fragment>
    )
}
export default withRouter(PrivacyPolicyPage);