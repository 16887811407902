import React from 'react';
import { AppPage, PrivacyPolicyPage, NotFoundPage } from '../components/pages';
import { routes as ROUTES } from './routes';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Page from '../components/pages/templates/Page';

const routes = () => (
    <BrowserRouter>
        <Switch>
            <Page {...ROUTES.APP_ROUTE} component={AppPage} />
            <Page {...ROUTES.MAP_ROUTE} component={AppPage} />
            <Route exact {...ROUTES.PRIVACY_POLICY_ROUTE} component={PrivacyPolicyPage} />
            {/** Every other route redirect to map route */}
            <Redirect to="/" />
            {/** Default handle NotFound if Redirect is not working */}
            <Route component={NotFoundPage} />
        </Switch>
    </BrowserRouter>
)
export default routes;