import 'ol/ol.css';
import React, { useEffect, useState, useRef } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Loading } from '../ui-kit/Loading';
import MapFactory from './mapFactory';
import { Typography, Popover, Fab, Tooltip, Grid, IconButton, Button, Icon, Divider } from '@material-ui/core';
import { BufferInfo, WithoutBuffer } from '../BufferInfo';
import { updateSelectedPlaceAction } from '../../store/actions/selectedPlaceActions';
import { setMapPropertiesAction } from '../../store/actions/mapPropertiesActions';
import { ConnectionForm } from '../gazConnection/ConnectionForm';
import { fromLonLat } from 'ol/proj';
import { ExternalLink } from '../ui-kit/ExternalLink';
import { MEDAL_FR, MEDAL_EN, MEDAL_DE } from '../../assets';
import { updateFormValueAction } from '../../store/actions/formValueActions';
import { setErrorAction } from '../../store/actions/errorActions';
import { ERROR_TYPES } from '../error/SnackError';
import { withRouter } from 'react-router-dom';
import { updateViewAction } from '../../store/actions/viewActions';
import { constants as LANGS } from '../LangPicker/constants';
import SearchService from '../../service/search/SearchService';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import { MAP_CONSTANTS } from './mapConstants';
import { string } from 'prop-types';

const ORANGE = 'rgba(238, 116, 23, 0.4)';
const GREEN = 'rgba(0, 133, 66, 0.4)';
const useStyles = makeStyles((theme) =>
    createStyles({
        map: {
            width: '100%',
            height: '100%;'
        },
        loading: {
            marginTop: theme.spacing(10)
        },
        attribution: {
            border: 'solid 1px lightgrey',
            background: 'white',
            padding: '8px 10px 10px 10px',
            textAlign: 'center',
            opacity: 0.7
        },
        infoPaper: {
            margin: theme.spacing(2),
            '& p': {
                padding: theme.spacing(0.8)
            }
        },
        infoTitle: {
            padding: theme.spacing(1),
            paddingLeft: theme.spacing(1.5),
            background: theme.palette.primary.main,
            color: 'white'
        },
        fabs: {
            position: 'absolute',
            bottom: 0,
            right: 0,
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
            paddingRight: theme.spacing(1)
        },
        leftAbsolute: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            margin: "0 0 10px 10px"
        },
        scaleLine: {
            '& .ol-scale-line': {
                marginTop: theme.spacing(1),
                position: 'relative',
                borderRadius: 'unset',
                padding: ' 8px 10px 8px 10px',
                border: 'solid 1px lightgrey',
                background: 'white',
                opacity: 0.7,
            },
            '& .ol-scale-line-inner': {
                fontSize: 15,
                border: ' 2px solid #546166',
                borderTop: 'none',
                color: '#546166',
            }
        }, // Scale line css overwrite
        controls: {
            '& .ol-control': {
                backgroundColor: 'unset'
            },
            '& .ol-control button': {
                margin: 0
            },
            '& .zoom-button button': {
                border: 'solid 1px lightgrey',
                fontSize: '25px',
                cursor: 'pointer',
                background: 'white',
                color: '#546166',
                padding: 0,
                opacity: 0.8,
            },
            '& .zoom-button': {
                position: 'absolute',
                right: 0,
                top: 0,
                margin: '75px 15px 0 0',
                padding: 0,
            },
            '& .zoom-button button:hover': {
                opacity: 0.8,
                background: '#f0f0f0',
                color: '#546166',
            },
            '& .zoom-button button:focus': {
                background: '#f0f0f0',
                color: ' #546166',
            }
        }, // Controls css overwrite
        medal: {
            [theme.breakpoints.only('sm')]: {
                maxWidth: '200px'
            },
            [theme.breakpoints.only('xs')]: {
                maxWidth: '140px'
            },
            [theme.breakpoints.up('md')]: {
                maxWidth: '250px'
            },
        },
        legendContent: {
            maxWidth: 650,
        },
        legendBox: {
            padding: theme.spacing(1.5),
        },
        legendRemark: {
            color: theme.palette.grey["600"],
            fontSize: 12,
        }
    })
);

const MapWidget = ({ center, zoom, translation, selectedLang, loading, selectedPlaceToState, mapPropertiesToState, formValueToState, errorToState, viewToState }) => {
    const classes = useStyles();
    // State
    const [map, setMap] = useState(); // Map definition
    const [popUpOpen, setPopUpOpen] = useState(false);
    const [popUpType, setPopUpType] = useState(10);
    const [formOpen, setFormOpen] = useState(false);
    const [legendOpen, setLegendOpen] = useState(false);
    // ref
    const mapRef = useRef(null); // Ref of the map
    // history
    const history = useHistory();

    useEffect(() => {
        initMap(); // Initialisation de la carte
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Réaction au survol de la carte
    // Permet également d'afficher ou non le curseur "pointer" si on survol un buffer
    const hoverHandler = (e) => {
        const pixel = e.map.getEventPixel(e.originalEvent);
        const hit = e.map.hasFeatureAtPixel(pixel);
        if (hit) {
            if (mapRef.current) {
                mapRef.current.style.cursor = 'pointer';
            }
            let type = 25;
            e.map.forEachFeatureAtPixel(pixel, function (feature, layer) {
                if (feature) {
                    switch (feature.values_.type) {
                        case 10:
                            type = 10;
                            break;
                        case 25:
                            break;
                        default: break;
                    }
                }
            });
            if (!popUpOpen) {
                setPopUpOpen(true);
            }
            setPopUpType(type);
        } else {
            if (mapRef.current) {
                mapRef.current.style.cursor = 'default';
            }
            setPopUpOpen(false);
        }
    }

    // Réaction au clique sur un buffer de la carte
    const clickHandler = (e) => {
        const pixel = e.map.getEventPixel(e.originalEvent);
        const hit = e.map.hasFeatureAtPixel(pixel);
        let type = null;
        if (hit) {
            type = 25;
            e.map.forEachFeatureAtPixel(pixel, function (feature, layer) {
                if (feature) {
                    switch (feature.values_.type) {
                        case 10:
                            type = 10;
                            setFormOpen(true);
                            break;
                        case 25:
                            setFormOpen(true);
                            break;
                        default: break;
                    }
                }
            });
        }
        selectedPlaceToState({ distance: type }); // Redux
    }

    // Map initialisation
    const initMap = async () => {
        let baseCenter = center;
        let baseZoom = zoom;
        try {
            mapPropertiesToState({ loading: true, center, zoom });
            const { initCenter, initZoom } = await handleQuery(); // Handle query param
            baseCenter = initCenter;
            baseZoom = initZoom;
            const mapFactory = new MapFactory(baseZoom, baseCenter);
            const map = await mapFactory.getMap();
            map.addEventListener('pointermove', hoverHandler); // Handle hover events
            map.addEventListener('click', clickHandler); // Handle click events
            setMap(map); // save map ref
        } catch (err) {
            console.log(err);
            errorToState({ type: ERROR_TYPES.INIT_ERROR, message: translation["INIT_ERROR"] }); // Redux error to state
        } finally {
            mapPropertiesToState({ loading: false, center: baseCenter, zoom: baseZoom });
        }
    }

    // Handle query in map initiation
    const handleQuery = async () => {
        // Check if query in url
        const query = history.location.search;
        const { search } = qs.parse(query);
        if (search && search.length > 0) {
            const searchService = new SearchService();
            const result = await searchService.searchPlaces(search);
            if (result && result.length > 0) {
                const place = result[0];
                selectedPlaceToState(place); // Select the first place
                return { initCenter: place.coord, initZoom: MAP_CONSTANTS.defaultZoom }; // Return the coord of the selected place
            } else {
                return { initCenter: center, initZoom: zoom }; // result empty, return base center and zoom
            }
        } else {
            return { initCenter: center, initZoom: zoom }; // Default return base center and zoom
        }
    }

    // Handle center and zoom change
    useEffect(() => {
        try {
            if (map) {
                const view = map.getView();
                view.setCenter(center);
                view.setZoom(zoom);
            }
        } catch (err) {
            errorToState({ type: ERROR_TYPES.MAP_ERROR, message: translation["MAP_ERROR"] }); // Redux error to state
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [center, zoom, map])

    const closeForm = () => {
        setPopUpOpen(false);
        setFormOpen(false);
    }

    const openForm = () => {
        formValueToState({ distance: 25 }); // on passe à 25 (car null = erreur sur webservice)
        setPopUpOpen(false);
        setFormOpen(true);
    }

    return (
        <React.Fragment>
            {/** LOADING */}
            {loading && <div className={classes.loading}><Loading label={translation["LOADING_TEXT"]} /></div>}
            {/** MAP */}
            <div ref={mapRef} id="map" className={`${classes.map} map`}></div>
            {/** Buffer info (on hover) */}
            <BufferInfo open={popUpOpen && !formOpen} type={popUpType} />
            {/** +/- CONTROLS */}
            <Controls />
            {/** LEFT MENU */}
            <WithoutBuffer>
               <div className={classes.leftAbsolute}>
                   <Grid container direction="column" justify="space-between" spacing={1}>
            {/*            <Grid item>*/}
                            {/** MEDAL */}
            {/*                <MedalButton tip={translation["MEDAL_TIP"]} lang={selectedLang} translation={translation}/>*/}
            {/*            </Grid>*/}
                       <Grid item>
                            {/** ATTRIBUTION  */}
                           <Attribution label={translation["ATTRIBUTION_TEXT"]} />
                       </Grid>
                   </Grid>
               </div>
            </WithoutBuffer>
            {/** FAB MENU */}
            <WithoutBuffer >
                <div className={classes.fabs}>
                    <Grid container direction="column" justify="space-between" alignItems="flex-end" spacing={1}>

                        <Grid item>
                            {/** (L) Map Legend */}
                            <Legend open={legendOpen} toggle={() => setLegendOpen(!legendOpen)} translation={translation} displayBufferInfo={() => viewToState({ bufferDisplay: 'block' })} />
                        </Grid>
                        <Grid item>
                            {/** (O) Center on me */}
                            <MyLocation mapPropertiesToState={mapPropertiesToState} tip={translation["CENTER_MY_LOCATION_TIP"]} errorToState={errorToState} />
                        </Grid>
                        <Grid item>
                            {/** (I) Site info */}
                            <SiteInfos toggleLegend={() => setLegendOpen(true)} translation={translation} openForm={openForm} displayBufferInfo={() => viewToState({ bufferDisplay: 'block' })} />
                        </Grid>
                        <Grid item>
                            {/** (+) Connection request */}
                            <ConnectionButton open={openForm} tip={translation["CONNECTION_REQUEST_TIP"]} />
                        </Grid>
                        <Grid item>
                            <ScaleLine />
                        </Grid>
                    </Grid>
                </div>
            </WithoutBuffer >
            <ConnectionForm isOpen={formOpen} close={closeForm} />
        </React.Fragment >
    )
}
MapWidget.propTypes = {
    center: PropTypes.array,
    loading: PropTypes.bool,
    zoom: PropTypes.number,
    history: PropTypes.object
}
// // //
// Redux connexion
const mapStateToProps = state => ({
    center: state.mapProperties.center,
    zoom: state.mapProperties.zoom,
    loading: state.mapProperties.loading,
    translation: state.lang.translation,
    selectedLang: state.lang.selected,
    bufferDisplay: state.view.bufferDisplay
})

const mapDispatchToProps = dispatch => {
    return {
        selectedPlaceToState: (val) => {
            dispatch(
                updateSelectedPlaceAction(val)
            )
        },
        mapPropertiesToState: (val) => {
            dispatch(
                setMapPropertiesAction(val)
            )
        },
        formValueToState: (val) => {
            dispatch(
                updateFormValueAction(val)
            )
        },
        errorToState: (val) => {
            dispatch(
                setErrorAction(val)
            )
        },
        viewToState: (val) => {
            dispatch(
                updateViewAction(val)
            )
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MapWidget));

// // //
// Map attribution custom
const Attribution = ({ label }) => {
    const classes = useStyles();

    return (
        <div className={classes.attribution}>
            <Typography component="p">{label} : <ExternalLink href="https://geoportail.wallonie.be/home.html" label="SPW" /></Typography>
        </div>
    )
}

// // //
// Scale line
const ScaleLine = () => {
    const classes = useStyles();

    return (
        <div className={classes.scaleLine} id="custom-scale-line"></div>
    )
}
// // //
// controls
const Controls = () => {
    const classes = useStyles();

    return (
        <WithoutBuffer>
            <div className={classes.controls} id="custom-control"></div>
        </WithoutBuffer>
    )
}

// // //
// Site infos
const SiteInfos = ({ translation, openForm, displayBufferInfo, toggleLegend }) => {
    const classes = useStyles();

    const buttonRef = useRef(null);
    const [open, setOpen] = useState(false);

    const toggle = () => {
        setOpen(!open);
    }

    const handleClose = () => {
        setOpen(false);
        //  !! This is a workaround !!
        setTimeout(() => {
            displayBufferInfo();
        }, 500); // We delay the opening because if not, it will be closed by the WithoutBuffer component.
    }

    const toggleForm = () => {
        openForm(); // open connexion form
        handleClose(); // Close site infos
    }

    // User clicked on legend button
    const handleLegendClicked = () => {
        toggle(); // Toggle site info
        toggleLegend(); // Toggle legend
    }

    return (
        <div>
            <Tooltip placement="left" title={translation["SITE_INFO_TIP"] ? translation["SITE_INFO_TIP"] : ''}>
                <Fab onClick={toggle} color="primary" ref={buttonRef}>
                    <Icon>info</Icon>
                </Fab>
            </Tooltip>
            <Popover
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                anchorEl={buttonRef.current}
                open={open}>
                <Grid
                    className={classes.infoTitle}
                    justify="space-between"
                    alignItems="center"
                    container>
                    <Grid item>
                        <Typography component="h3">
                            {translation["SITE_INFO_TITLE"]}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton
                            onClick={handleClose}
                            color="inherit">
                            <Icon>close</Icon>
                        </IconButton>
                    </Grid>
                </Grid>
                <div className={classes.infoPaper}>
                    <Typography component="p">
                        {translation["SITE_INFO_MADE_BY"]}
                    </Typography>
                    <Typography component="p">
                        {translation["SITE_INFO_REQUEST_1"]}
                        <Button color="primary" onClick={toggleForm}>{translation["SITE_INFO_REQUEST_LINK"]}</Button>
                    </Typography>
                    <Typography component="p">
                        {translation["SITE_INFO_DOCS"]}
                        <ExternalLink href={translation["URL_SITE_RACCORDEMENT"]} label={translation["SITE_INFO_DOCS_LINK"]} />
                    </Typography>
                    <Typography component="p">
                        {translation["SITE_INFO_CONTACT"]}
                        <ExternalLink href={`mailto:${translation["MAIL_VOTRE_SERVICE"]}`} label={translation["MAIL_VOTRE_SERVICE"]} />
                    </Typography>
                    <Typography component="p">
                        {translation["MAP_LEGEND_TITLE"]}
                        <Button color="primary" onClick={handleLegendClicked}>{translation["SITE_INFO_REQUEST_LINK"]}</Button>
                    </Typography>
                </div>
            </Popover>
        </div >
    )
}

// // //
// Center my location button
const MyLocation = ({ tip, mapPropertiesToState, errorToState }) => {
    const [disabled, setDisabled] = useState(false);
    const [currentLocation, setCurrentLocation] = useState(null);

    useEffect(() => {
        const geo = navigator.geolocation; // Geolocalisation API
        if (!geo) {
            setDisabled(true); // Disable the button
            return;
        } else {
            const watcher = geo.watchPosition(onPositionChange, onError);
            return () => geo.clearWatch(watcher);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onPositionChange = (e) => {
        const { coords } = e;
        setCurrentLocation([coords.longitude, coords.latitude]); // update current location, used to recenter
    }

    // Error happened
    const onError = (e) => {
        setDisabled(true); // Disable the input
        errorToState({ type: ERROR_TYPES.LOCALISATION_ERROR });
    }

    const center = () => {
        try {
            const result = fromLonLat(currentLocation, 'EPSG:31370'); // XY from latitude and longitude
            mapPropertiesToState({ center: result, zoom: 11 }); // Recenter
        } catch (err) {
            errorToState({ type: ERROR_TYPES.MAP_ERROR });
        }
    }

    return currentLocation && (
        <Tooltip placement="left" title={tip ? tip : ''}>
            <Fab onClick={center} color="primary" disabled={disabled}>
                <Icon>location_searching</Icon>
            </Fab>
        </Tooltip>
    )
}

// // //
// Connection button
const ConnectionButton = ({ tip, open }) => {

    return (
        <Tooltip placement="left" title={tip ? tip : ''}>
            <Fab onClick={open} color="primary">
                <Icon >description_outlined</Icon>
            </Fab>
        </Tooltip>
    )
}

// // //
// Medal button
const MedalButton = ({ translation, tip, lang = 'fr' }) => {
    const classes = useStyles();
    const [medal, setMedal] = React.useState(MEDAL_FR); // Medal import used to display medal img

    // Switch medal based on lang
    useEffect(() => {
        switch (lang) {
            case LANGS.LANG_FR:
                setMedal(MEDAL_FR);
                break;
            case LANGS.LANG_EN:
                setMedal(MEDAL_EN);
                break;
            case LANGS.LANG_DE:
                setMedal(MEDAL_DE);
                break;
            default: // Default value is fr
                setMedal(MEDAL_FR);
                break;
        }
    }, [lang]);

    return (
        <Tooltip placement="right" title={tip ? tip : ''}>
            <a href={translation["URL_SITE_CONSEIL"]} target="_blank" rel="noopener noreferrer">
                <IconButton color="primary">
                    <img className={classes.medal} src={medal} alt="medal" />
                </IconButton>
            </a>
        </Tooltip>
    )
}

// // //
// Legend component
const Legend = ({ translation, displayBufferInfo, toggle, open = false }) => {
    const classes = useStyles();
    // Ref
    const buttonRef = useRef(null);

    const handleClose = () => {
        toggle();
        //  !! This is a workaround !!
        setTimeout(() => {
            displayBufferInfo();
        }, 500); // We delay the opening because if not, it will be closed by the WithoutBuffer component.
    }

    return (
        <div>
            <Tooltip placement="left" title={translation["MAP_LEGEND_TITLE"] ? translation["MAP_LEGEND_TITLE"] : ''}>
                <Fab onClick={() => toggle()} color="primary" ref={buttonRef}>
                    <Icon>notes</Icon>
                </Fab>
            </Tooltip>
            <Popover
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                anchorEl={buttonRef.current}
                open={open}>
                <div className={classes.legendContent}>
                    <Grid
                        justify="space-between"
                        alignItems="center"
                        container
                        className={classes.infoTitle}>
                        <Grid item>
                            <Typography component="h3" >
                                {translation["MAP_LEGEND_TITLE"]}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton
                                onClick={handleClose}
                                color="inherit">
                                <Icon>close</Icon>
                            </IconButton>
                        </Grid>
                    </Grid>
                    <div className={classes.infoPaper}>
                        <Grid
                            direction="column"
                            container
                            spacing={1}>
                            {/** ORANGE */}
                            <Grid item>
                                <Grid
                                    spacing={1}
                                    alignItems="center"
                                    container>
                                    <Grid item>
                                        <div
                                            style={{ backgroundColor: ORANGE }}
                                            className={classes.legendBox} />
                                    </Grid>
                                    <Grid item>
                                        <Typography>{translation["LEGENDE_GAZ_PROCHE"]}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/** GREEN */}
                            <Grid item>
                                <Grid
                                    alignItems="center"
                                    container
                                    spacing={1}>
                                    <Grid item>
                                        <div
                                            style={{ backgroundColor: GREEN }}
                                            className={classes.legendBox} />
                                    </Grid>
                                    <Grid item>
                                        <Typography>{translation["LEGENDE_GAZ_DEVANT"]}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/** NO COLOR */}
                            <Grid item>
                                <Grid
                                    alignItems="center"
                                    container
                                    spacing={1}>
                                    <Grid item>
                                        <Typography component="p"><b>{translation["NO_COLOR"]}</b></Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography>{translation["LEGENDE_SANS_GAZ"]}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Typography className={classes.legendRemark}>{translation["LEGENDE_REMARQUE"]}</Typography>
                    </div>
                </div>
            </Popover>
        </div>
    );
};