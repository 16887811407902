import Map from 'ol/Map';
import View from 'ol/View';
import Zoom from 'ol/control/Zoom';
import ScaleLine from 'ol/control/ScaleLine';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import { get as getProjection } from 'ol/proj';
import { Vector as VectorSource } from 'ol/source';
import TileWMS from 'ol/source/TileWMS';
import proj4 from 'proj4/dist/proj4';
import { register } from 'ol/proj/proj4';
import { Fill, Style } from 'ol/style';
import BufferService from '../../service/buffers/BufferService';
import { ProjectionFactory } from './projectionFactory';
import { MAP_CONSTANTS } from './mapConstants';

// Module de création d'une carte basée sur la librairie openlayers
// Ce module contient également la définition des Projections utilisées (31370 et 3812)
export default class MapFactory {
    constructor(zoom, center) {
        this.zoom = zoom;
        this.center = center;
        this._VIEW_CONFIGURATION = {
            minZoom: MAP_CONSTANTS.minZoom,
            maxZoom: MAP_CONSTANTS.maxZoom,
            zoomFactor: MAP_CONSTANTS.zoomFactor,
        }; // Base config of the map views
        this._BASE_WMS_CONFIGURATION = {
            url: 'https://geoservices.wallonie.be/arcgis/services/TOPOGRAPHIE/PICC_VDIFF/MapServer/WMSServer',
            params: { 'LAYERS': "1,8,9,10,11,12,13,17,18,19,20,21,28&", 'TILED': true },
            serverType: 'geoserver',
        }; // Base config of the VMS Tile Layer
    }

    //  Map initialisation
    getMap = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const bufferService = new BufferService();
                const buffers = await bufferService.fetchBuffers(); // Fetch buffers
                const projection = this._initProjection(); // Initialisation de la projection Belgium Lambert 72
                const view = this._initView(projection); // Initialisation de la Vue
                const { orangeVectorLayer, greenVectorLayer } = this._buildBuffersVector(buffers); // Initialisation des Vecteurs des buffers
                // Initialisation de la carte
                const map = new Map({
                    layers: [
                        new TileLayer({
                            opacity: 0.7,
                            extent: projection.getExtent(),
                            source: new TileWMS({
                                ...this._BASE_WMS_CONFIGURATION,
                                projection
                            })
                        }),
                        orangeVectorLayer,
                        greenVectorLayer
                    ],
                    controls: [new Zoom({
                        zoomInLabel: '+',
                        zoomOutLabel: '-',
                        zoomInTipLabel: '',
                        zoomOutTipLabel: '',
                        className: 'zoom-button',
                        target: 'custom-control'
                    }), // Custom zoom
                    new ScaleLine({
                        target: 'custom-scale-line'
                    })
                    ],
                    target: 'map'
                });
                map.setView(view); // View init
                resolve(map); // Success
            } catch (err) {
                reject(err); // error
            }
        })
    }

    // Initialisation de la projection Belgium Lambert 72
    _initProjection = () => {
        proj4.defs("EPSG:31370", "+proj=lcc +lat_1=51.16666723333333 +lat_2=49.8333339 +lat_0=90 +lon_0=4.367486666666666 +x_0=150000.013 +y_0=5400088.438 +ellps=intl +towgs84=-106.869,52.2978,-103.724,0.3366,-0.457,1.8422,-1.2747 +units=m +no_defs")
        register(proj4); // Enregistrement de la projection
        const proj31370 = getProjection('EPSG:31370'); // Récupération de la projection enregistrée
        proj31370.setExtent([14637.247371719597, 22608.210149566643, 297133.1319292541, 246424.2752103461]); // Mise en place des "extents", les bbox
        return proj31370; // Return
    }
    
    // View is set to Lambert 72
    _initView = (projection) => {
        return new View({
            ...this._VIEW_CONFIGURATION,
            projection,
        })
    }

    // Construction des "Vectors" utilisés pour le buffer type 10 et le buffer type 25
    _buildBuffersVector = (buffers) => {
        const { green, orange } = this._splitBuffers(buffers);
        const orangeStyle = {
            'Polygon': new Style({
                fill: new Fill({
                    color: 'rgba(238, 116, 23, 0.4)'
                })
            })
        }
        const greenStyle = {
            'Polygon': new Style({
                fill: new Fill({
                    color: 'rgba(0, 133, 66, 0.4)'
                })
            })
        }
        const greenSource = new VectorSource({
            features: (new GeoJSON()).readFeatures(green)
        });
        const orangeSource = new VectorSource({
            features: (new GeoJSON()).readFeatures(orange)
        });
        const greenVectorLayer = new VectorLayer({
            source: greenSource,
            style: (feature) => {
                return greenStyle[feature.getGeometry().getType()];
            }
        });
        const orangeVectorLayer = new VectorLayer({
            source: orangeSource,
            style: (feature) => {
                return orangeStyle[feature.getGeometry().getType()];
            }
        });
        return { orangeVectorLayer, greenVectorLayer }
    }

    // Séparation des buffers en 2 type différents
    // Le buffer 10 = celui qui est prêt
    // Le buffer 25 = celui qui est plus loin
    _splitBuffers = (buffers) => {
        const greenFeatures = buffers.features.filter(b => b.properties.type === 10)
        const orangeFeatures = buffers.features.filter(b => b.properties.type === 25)
        const green = { ...buffers, features: greenFeatures, totalFeatures: greenFeatures.length, numberMatched: greenFeatures.length, numberReturned: greenFeatures.length }
        const orange = { ...buffers, features: orangeFeatures, totalFeatures: orangeFeatures.length, numberMatched: orangeFeatures.length, numberReturned: orangeFeatures.length }
        return { green, orange }
    }
}