const FLAG_EN = require('./en-flag.png');
const FLAG_FR = require('./fr-flag.png');
const FLAG_DE = require('./ge-flag.png');
const MEDAL_FR = require('./MEDAL_FR.png');
const MEDAL_EN = require('./MEDAL_EN.png');
const MEDAL_DE = require('./MEDAL_DE.png');
const RESA_LOGO = require('./resa-logo-white.png');

export {
    FLAG_EN,
    FLAG_FR,
    FLAG_DE,
    MEDAL_FR,
    MEDAL_EN,
    MEDAL_DE,
    RESA_LOGO,
};