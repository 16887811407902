import RestService from "../RESTService";
import axios from 'axios';

export default class SearchService extends RestService {
    constructor() {
        super(`${process.env.REACT_APP_API_BASE_URL}/search`);
    }

    // Recherche des lieux
    searchPlaces = (val) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { data } = await axios.get(`${super.getUri()}/${val}`);
                resolve(data); // Success
            } catch (err) {
                reject(err); // Error
            }
        })
    }
}