import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Snackbar, IconButton, SnackbarContent, Icon } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ERROR_TYPES } from '.';

const useStyles = makeStyles(theme => createStyles({
    close: {
        padding: theme.spacing(0.5),
    },
    warning: {
        backgroundColor: theme.palette.error.dark,
    },
    icon: {
        fontSize: 20,
        marginRight: theme.spacing(1),
    }, message: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const SnackError = ({ error, translation }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false); // Call to close function
    };

    // React when the error change (an error is thrown)
    useEffect(() => {
        if (error.type) {
            setOpen(true); // open the snackbar
        }
    }, [error])

    // Get message when it is undefined is undefined
    const getMessage = () => {
        switch (error.type) {
            case ERROR_TYPES.LOCALISATION_ERROR:
                return translation["LOCATION_ERROR"];
            case ERROR_TYPES.MAP_ERROR:
                return translation["MAP_ERROR"];
            case ERROR_TYPES.LANG_ERROR:
                return translation["LANG_ERROR"];
            case ERROR_TYPES.INIT_ERROR:
                return translation["INIT_ERROR"];
            case ERROR_TYPES.FORM_ERROR:
                return translation["FORM_ERROR"];
            default: break;
        }
    }

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            open={open}
            autoHideDuration={10000}
            onClose={handleClose}
        >
            <SnackbarContent
                className={classes.warning}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon className={classes.icon} >error</Icon>
                        {error.message ? error.message : getMessage()}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleClose}
                    >
                        <Icon>close</Icon>
                    </IconButton>
                ]}
            />
        </Snackbar>)
}
SnackError.propTypes = {
    error: PropTypes.shape({
        message: PropTypes.string,
        type: PropTypes.string
    }),
    translation: PropTypes.object
}
// // //
// Redux connection
const mapStateToProps = state => ({
    error: state.error,
    translation: state.lang.translation
})

const mapDispatchToProps = dispatch => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SnackError);