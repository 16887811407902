import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateViewAction } from '../../store/actions/viewActions';

// Composant utilisé pour injecter le contenu d'un élément qui doit cacher les buffers
// Ce composant est un composant de haut niveau
const WithoutBuffer = ({ children, viewToState }) => {


    const display = () => {
        viewToState({ bufferDisplay: 'block' }); // Redux
    }

    const hide = () => {
        viewToState({ bufferDisplay: 'none' }); // Redux
    }

    return (
        <div className="without-buffer" onMouseEnter={hide} onMouseLeave={display}>
            {children}
        </div>
    )

}
WithoutBuffer.propTypes = {
    children: PropTypes.node,
    bufferDisplay: PropTypes.string,
    viewToState: PropTypes.func
}
// // //
// Redux connection
const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => {
    return {
        viewToState: (val) => {
            dispatch(
                updateViewAction(val)
            )
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(WithoutBuffer);
