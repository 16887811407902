import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dialog, DialogContent, Tooltip, DialogTitle, DialogActions, Button, TextField, FormControl, InputLabel, Grid, Slider, Typography, Select, MenuItem, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { updateFormValueAction } from '../../../store/actions/formValueActions';
import { ConnectionResult } from '../ConnectionResult';
import ConnectionService from '../../../service/connection/ConnectionService';
import { Loading } from '../../ui-kit/Loading';
import { ERROR_TYPES } from '../../error/SnackError';
import { setErrorAction } from '../../../store/actions/errorActions';

const useStyles = makeStyles((theme) =>
    createStyles({
        sliderLabel: {
            color: "#757575"
        },
        dialogTitle: {
            background: theme.palette.primary.main,
            color: "white"
        },
        formContent: {
            marginTop: 0,
            marginBottom: 0
        },
        dialogContent: {
            [theme.breakpoints.up('sm')]: {
                minWidth: 600,
            }
        },
        resultContent: {
            margin: theme.spacing(2)
        }
    })
);
const ConnectionForm = ({ isOpen, close, translation, formValue, formValueToState, selectedPlace, lang, errorToState }) => {
    const classes = useStyles();
    const theme = useTheme();
    const [disabled, setDisabled] = useState(true);
    const [recaptcha, setRecaptcha] = useState(false); // Recaptcha to validate once
    const [emailError, setEmailError] = useState(false);
    const [hasResult, setHasResult] = useState(false);
    const [hasError, setHasError] = useState(false); // Erreur après submit
    const [loading, setLoading] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs')); // responsive dialog

    // Text formatting for slider
    const valuetext = (val) => {
        return `${val} ${translation["FORM_ADDRESS_GAZ_METTER_TYPO"]}`;
    }

    // Marques du slider
    const marks = [
        {
            value: 1,
            label: `${translation["FORM_ADDRESS_GAZ_METTER_TYPO_1"]}`,
        },
        {
            value: 2,
            label: `${translation["FORM_ADDRESS_GAZ_METTER_TYPO_2"]}`,
        },
        {
            value: 3,
            label: `${translation["FORM_ADDRESS_GAZ_METTER_TYPO_3"]}`,
        },
    ];

    // Option du select
    const options = [
        { value: "personal", label: translation["FORM_ADDRESS_BUILDING_TYPE_INDIVIDUAL"] },
        { value: "company", label: translation["FORM_ADDRESS_BUILDING_TYPE_PROFESSIONAL"] },
    ];

    useEffect(() => {
        // Modification lorsque le "selectedPlace" change
        const street = selectedPlace.displayName ? selectedPlace.displayName : '';
        const streetCP = selectedPlace.cp ? selectedPlace.cp : '';
        const streetTown = selectedPlace.localite ? selectedPlace.localite : '';
        const distance = selectedPlace.distance ? selectedPlace.distance : null;
        formValueToState({ street, streetCP, streetTown, distance }); // Redux
    }, [selectedPlace, formValueToState])

    // Réaction à la modification de la langue
    useEffect(() => {
        formValueToState({ lang }); // Redux
    }, [lang, formValueToState])

    // Réaction à la modification d'un input
    const handleChange = (e) => {
        const target = e.target;
        let value = target.value;
        if (target.type === 'number') {
            try {
                value = parseInt(target.value);
                value = value > 9999 ? 9999 : value;
            } catch (error) {
                value = 0; // Error while parsing
            }
        } else if (target.name === 'email') {
            const regex = RegExp('.*@[a-zA-z]+\\.[a-zA-Z]+');
            const match = regex.test(target.value); // Email validation
            setEmailError(!match);
        }
        formValueToState({ [`${target.name}`]: value }); // Redux
        checkDisabled({ ...formValue, [`${target.name}`]: value }); // Check if the submit is disabled or not
    }

    // On change select option
    const handleSelectChange = (e) => {
        const target = e.target;
        const { name, value } = target;
        formValueToState({ [`${name}`]: value }); // Redux
        checkDisabled({ ...formValue, [`${name}`]: value }); // Check if the submit is disabled or not
    }

    // On change slider value
    const handleSliderChange = (e, value) => {
        formValueToState({ gazMetterNumber: value }); // Redux
        checkDisabled({ ...formValue, gazMetterNumber: value }); // Check if the submit is disabled or not
    }
    // on change la valeur du recaptcha
    const handleRecaptcha = (value) => {
        setRecaptcha(value); // Validate
        console.log(value);
        formValueToState({ recaptcha: value }); // Set recaptcha in form to validate it in back-end
    }

    // Fonction utilisée après chaque modification du formulaire pour vérifier que celui-ci est bien rempli
    const checkDisabled = (newVal) => {
        let isDisabled = false;
        Object.keys(newVal).forEach(key => {
            if (newVal[key] === '') {
                isDisabled = true;
            }
        });
        setDisabled(emailError ? true : isDisabled);
    }

    // Checking when email error change if we should disable the submit button
    useEffect(() => {
        checkDisabled({ ...formValue });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailError])

    const cancel = () => {
        close();
        setTimeout(() => {
            setHasResult(false); // On reset le resultat s'il y en a un
        }, 1000);
        setHasError(false);
    }

    const redirect = async () => {
        window.location.href = translation["URL_SITE_RACCORDEMENT_FORM"];
    }

    const submit = async () => {
        setLoading(true);
        const body = formValue;
        switch (formValue.gazMetterNumber) {
            case 1:
                // '1'
                body.gazMetterNumber = '1';
                break;
            case 2:
                // '2to4'
                body.gazMetterNumber = '2to4';
                break;
            case 3:
                // '5+'
                body.gazMetterNumber = '5+';
                break;
            default: break;
        }
        const service = new ConnectionService();
        try {
            await service.connectionRequest(body); // request
        } catch (error) {
            setHasError(true); // Display error in result component
            errorToState({ type: ERROR_TYPES.FORM_ERROR, message: translation["FORM_ERROR"] }); // Redux error
        } finally {
            setLoading(false);
            setHasResult(true); // Display result component
            setRecaptcha(false); // Reinitialize recaptcha 
        }
    }

    return (
        <div>
            <Dialog maxWidth="sm" fullScreen={fullScreen} open={isOpen} onClose={cancel}>
                <DialogTitle className={classes.dialogTitle}>
                    {formValue.distance === 10 ?
                        translation["CONNECTION_FORM_10_TITLE"]
                        :
                        translation["CONNECTION_FORM_25_TITLE"]
                    }
                </DialogTitle>
                <React.Fragment>
                    {/** FORM */}
                    <DialogContent className={classes.dialogContent} dividers>
                        <div className={classes.formContent}>
                        <Grid justify="center" container spacing={2}>
                        <Typography variant="title" color="inherit" noWrap>
                                &nbsp;
                            </Typography>
                            </Grid>
                            <Grid justify="center" container spacing={2}>
                            <Typography component="h4" >{translation["REDIRECT_FORM_BODY"]}</Typography>
                            </Grid>
                            <Grid justify="center" container spacing={2}>
                            <Typography variant="title" color="inherit" noWrap>
                                &nbsp;
                            </Typography>
                            </Grid>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={cancel} color="primary">
                            {translation["CANCEL_FORM"]}
                        </Button>
                        <Button type="button" onClick={redirect} variant="contained" color="primary">
                            {translation["REDIRECT_FORM"]}
                        </Button>
                    </DialogActions>
                </React.Fragment>
            </Dialog>
        </div>
    )
}
// // // 
// Redux connexion
const mapStateToProps = state => ({
    translation: state.lang.translation,
    formValue: state.formValue,
    selectedPlace: state.selectedPlace,
    lang: state.lang.selected
})

const mapDispatchToProps = dispatch => {
    return {
        formValueToState: (val) => {
            dispatch(
                updateFormValueAction(val)
            )
        },
        errorToState: (val) => {
            dispatch(
                setErrorAction(val)
            )
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ConnectionForm);