import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, InputBase, Paper, Divider, List, ListItem, ListItemIcon, Typography, ClickAwayListener, Collapse, ListItemText, Icon } from '@material-ui/core';
import { connect } from 'react-redux';
import { setMapPropertiesAction } from '../../store/actions/mapPropertiesActions';
import { setSelectedPlaceAction } from '../../store/actions/selectedPlaceActions';
import SearchService from '../../service/search/SearchService';
import { Loading } from '../ui-kit/Loading';
import clsx from 'clsx';
import { ERROR_TYPES } from '../error/SnackError';
import { setErrorAction } from '../../store/actions/errorActions';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import { routes as ROUTES } from '../../router/routes';
import { MAP_CONSTANTS } from '../MapWidget/mapConstants';

const xsWidth = 200;
const smWidth = 350;
const mdWidth = 500;
const xlWidth = 600;

const useStyles = makeStyles(theme => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
    },
    width: {
        [theme.breakpoints.only('xs')]: {
            width: xsWidth
        },
        [theme.breakpoints.only('sm')]: {
            width: smWidth
        },
        [theme.breakpoints.only('md')]: {
            width: mdWidth
        },
        [theme.breakpoints.up('lg')]: {
            width: xlWidth
        },
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            width: 40
        },
        [theme.breakpoints.only('xs')]: {
            width: 25
        },
    },
    divider: {
        height: 28,
        margin: 4,
    },
    suggestions: {
        position: 'absolute',
        marginTop: theme.spacing(0.5),
        overflow: 'auto',
        [theme.breakpoints.only('xs')]: {
            maxHeight: 200
        },
        [theme.breakpoints.only('sm')]: {
            maxHeight: 300
        },
        [theme.breakpoints.up('md')]: {
            maxHeight: 450
        },
    },
    suggestionItem: {
        '&:hover': {
            color: "#1E1E1E"
        },
        cursor: 'pointer'
    },
    suggestiontext: {
        [theme.breakpoints.up('sm')]: {
            fontSize: 13
        },
        [theme.breakpoints.only('xs')]: {
            fontSize: 11
        }
    },
    selectedSuggestion: {
        background: theme.palette.primary.main,
        color: 'white',
    },
    noResult: {
        display: 'flex',
        justifyContent: 'center',
        color: 'gray'
    }
}));

// Searchbar liée à la map, elle permet d'effectuer les requêtes vers le serveur pour avoir les suggestions
// Elle permet d'effectuer la recherche d'une position et de se centrer sur celle-ci
// cf https://github.com/downshift-js/downshift (implementing WAI-ARIA components)
const Searchbar = ({ translation, selectedPlace, selectedPlaceToState, mapPropertiesToState, errorToState, mapLoading, error }) => {
    const classes = useStyles();
    // State
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [timer, setTimer] = useState(null); // Timer used to delay fetch places
    const [open, setOpen] = useState(false);
    const [displayResult, setDisplayResult] = useState(false);
    // router
    const history = useHistory();

    // When selectedplace change (provided here or by map initialisation), change inputValue
    useEffect(() => {
        if (selectedPlace && selectedPlace.coord && mapLoading) {
            const inputValue = selectedPlace.completeAddress ? selectedPlace.completeAddress : selectedPlace.composedAddress;
            setInputValue(inputValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPlace]);

    const handleChange = (e) => {
        setOpen(true);
        const target = e.target;
        const value = target.value; // Remove whitespaces
        setInputValue(value); // State
        if (timer) {
            clearTimeout(timer); // On clear le timer
        }
        setTimer(setTimeout(() => {
            getSuggestions(value); // Fetch
        }, 500));
    }

    // Fetch of the suggestions (places)
    const getSuggestions = async (value) => {
        try {
            setLoading(true);
            if (value.length >= 2) {
                const service = new SearchService(); // Service de recherche de lieux
                const results = await service.searchPlaces(value); // Fetch
                setSuggestions(results); // on met à jour les suggestions affichées
                setDisplayResult(true); // Should display
            }
        } catch (error) {
            errorToState({ message: translation["SEARCH_ERROR"], type: ERROR_TYPES.SEARCH_ERROR }); // Redux error
        } finally {
            setLoading(false);
            setOpen(true); // Shoudl open when fetch done
        }
    }

    // Sélection d'un élément de la liste de suggestions
    const changeSelectedSuggestion = (place) => {
        selectedPlaceToState(place); // Redux new selected Place
        const inputValue = place.completeAddress ? place.completeAddress : place.composedAddress;
        setInputValue(inputValue);
        mapPropertiesToState({ zoom: MAP_CONSTANTS.defaultZoom, center: place.coord }); // Redux update map
        setQuery({ search: inputValue }); // Update query (url)
        setOpen(false);
    }

    const resetSearch = () => {
        setInputValue(''); // Reset search text
        setSuggestions([]); // Reset suggestions
        setDisplayResult(false); // Shouldnt display
    }

    const openMenu = () => {
        setOpen(true);
    }
    const closeMenu = () => {
        setOpen(false);
    }

    // Update query in URL on selected suggestion change
    const setQuery = (query) => {
        const queryAsString = qs.stringify(query); // Parse query object to string
        history.push({
            pathname: ROUTES.MAP_ROUTE.path,
            search: queryAsString,
        });
    };

    return (
        <ClickAwayListener onClickAway={closeMenu}>
            <div onFocus={openMenu}>
                {/** SEARCH BAR */}
                <Paper onClick={openMenu} className={clsx(classes.root, classes.width)}>
                    <InputBase
                        disabled={mapLoading}
                        value={inputValue}
                        onChange={handleChange}
                        className={classes.input}
                        placeholder={translation["SEARCH_INPUT_PLACEHOLDER"]}
                        inputProps={{ 'aria-label': 'search a place' }}
                    />
                    {inputValue.length > 0 ?
                        < IconButton onClick={resetSearch} className={classes.iconButton} aria-label="directions">
                            <Icon>clear</Icon>
                        </IconButton> : null}
                    <Divider className={classes.divider} orientation="vertical" />
                    <IconButton disabled className={classes.iconButton} aria-label="search">
                        {loading ? <Loading size={22} /> : <Icon>search</Icon>}
                    </IconButton>
                </Paper>
                {/** SUGGESTIONS */}
                <Collapse in={open && displayResult} >
                    <Paper className={clsx(classes.suggestions, classes.width)}>
                        <List>
                            {suggestions.map((suggestion, key) => (
                                <ListItem button className={clsx(classes.suggestionItem, { [classes.selectedSuggestion]: suggestion.composedAddress === selectedPlace.composedAddress })} key={key} onClick={() => changeSelectedSuggestion(suggestion)}>
                                    <ListItemIcon><Icon>place_outlined</Icon></ListItemIcon>
                                    <Typography className={classes.suggestiontext}>{suggestion.completeAddress ? suggestion.completeAddress : suggestion.composedAddress}</Typography>
                                </ListItem>
                            ))}
                            {suggestions.length === 0 ?
                                <ListItem>
                                    <ListItemText className={classes.noResult}>{translation["SEARCH_NO_RESULT"]}</ListItemText>
                                </ListItem>
                                : null}
                        </List>
                    </Paper >
                </Collapse>
            </div>
        </ClickAwayListener>
    )
}
// // //
// Redux connexion
const mapStateToProps = state => ({
    translation: state.lang.translation,
    selectedPlace: state.selectedPlace,
    mapLoading: state.mapProperties.loading,
})

const mapDispatchToProps = dispatch => {
    return {
        selectedPlaceToState: (val) => {
            dispatch(
                setSelectedPlaceAction(val)
            )
        },
        mapPropertiesToState: (val) => {
            dispatch(
                setMapPropertiesAction(val)
            )
        },
        errorToState: (val) => {
            dispatch(
                setErrorAction(val)
            )
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Searchbar);