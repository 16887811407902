import React from 'react';
import { MapWidget } from '../MapWidget';
import { WelcomeBanner } from '../WelcomeBanner';

const AppPage = () => {
    return (
        <React.Fragment>
            <MapWidget />
            <WelcomeBanner />
        </React.Fragment>
    )
}
export default AppPage;