import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#E94E0F",
        },
        secondary: {
            main: "#A01A21"
        }
    },
}); 