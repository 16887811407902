import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Paper } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => createStyles({
    root: {
        position: 'absolute',
        padding: theme.spacing(2),
        overflow: 'hidden',
        zIndex: 10
    },
    content: {
        marginTop: theme.spacing(1),
        color: 'gray'
    }
}));

const BufferInfo = ({ type, open, translation, bufferDisplay }) => {
    const classes = useStyles();
    const [left, setLeft] = useState(0);
    const [top, setTop] = useState(0);

    // Follow the mouse
    window.onmousemove = (e) => {
        const x = e.clientX,
            y = e.clientY;
        const { innerWidth, innerHeight } = window;
        let leftPos, bottomPos = 0;
        if (x + 300 > innerWidth) {
            leftPos = x - 300;
        } else {
            leftPos = x + 10;
        }
        if (y + 100 > innerHeight) {
            bottomPos = y - 125;
        } else {
            bottomPos = y + 10;
        }
        setLeft(`${leftPos}px`);
        setTop(`${bottomPos}px`);
    }

    return open ? (
        <React.Fragment>
            <Paper
                style={{ left, top, display: bufferDisplay }}
                className={classes.root}>
                <Typography component="h5" variant="h6">{translation[`POPUP_BUFFER_${type}_TITLE`]}</Typography>
                <Typography className={classes.content} component="p">{translation[`POPUP_BUFFER_${type}_CONTENT`]}</Typography>
            </Paper>
        </React.Fragment>

    ) : null
}
BufferInfo.propTypes = {
    type: PropTypes.number
}
BufferInfo.defaultProps = {
    type: 10,
    open: false
}
// // //
// Redux connexion
const mapStateToProps = state => ({
    translation: state.lang.translation,
    bufferDisplay: state.view.bufferDisplay
})

const mapDispatchToProps = dispatch => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BufferInfo);