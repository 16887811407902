import { constants as C } from '../actions/constants';
import defaultTranslation from '../default/langData.json';

export const lang = (state = { translation: defaultTranslation, selected: "", data: {} }, action) => {
    switch (action.type) {
        case C.SET_LANG:
            return action.payload
        default:
            return state
    }
}