import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
    createStyles({
        loading: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }
    })
)
const Loading = ({ label, color, size = 0 }) => {

    const classes = useStyles();
    return (
        <div className={classes.loading} >
            {label && <Typography component="p">{label}</Typography>}
            <CircularProgress style={{ color: color ? color : '' }} size={size ? size : 30} color='primary' />
        </div>
    )
}

export default Loading;