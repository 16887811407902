import React, { useEffect, useState } from 'react';
import { setLangAction } from '../../store/actions/langActions';
import { connect } from 'react-redux';
import { constants as C } from './constants';
import LangFactory from '../../lang/LangFactory';
import { Menu, MenuItem, Button, ListItemIcon,Icon } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import LocalStorageManager from '../../utils/LocalStorageManager';
import LangService from '../../service/lang/LangService';
import { ERROR_TYPES } from '../error/SnackError';
import { setErrorAction } from '../../store/actions/errorActions';
import { FLAG_FR, FLAG_DE, FLAG_EN } from '../../assets';

const useStyles = makeStyles((theme) =>
    createStyles({
        flag: {
            maxWidth: 35,
            maxHeight: 30
        },
        flagButton: {
            display: 'flex',
            justifyContent: 'center'
        },
        selectedFlag: {
            maxWidth: 25,
            marginRight: 5,
            maxHeight: 20
        }
    })
);

const LangPicker = ({ lang, langToState, errorToState }) => {
    const { translation } = lang;
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();

    const options = [
        { label: translation["LANG_FR"], value: C.LANG_FR, icon: FLAG_FR },
        { label: translation["LANG_DE"], value: C.LANG_DE, icon: FLAG_DE },
        { label: translation["LANG_EN"], value: C.LANG_EN, icon: FLAG_EN },
    ]; // Options utilisées (langues)

    useEffect(() => {
        initLangs(); // Initilisation du fichier de langues
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const initLangs = async () => {
        try {
            const factory = new LangFactory();
            const manager = new LocalStorageManager();
            const selectedLang = manager.getItem(manager._CONSTANTS.SELECTED_LANG);
            let valueSelected = '', index = 0, label = '';
            if (selectedLang) {
                valueSelected = selectedLang;
                index = options.findIndex(v => v.value === valueSelected);
            } else {
                valueSelected = C.LANG_FR;
                index = 0;
                manager.setItem(manager._CONSTANTS.SELECTED_LANG, C.LANG_FR);
            }
            const service = new LangService(); // Service de récupération des langues
            const DATA = await service.fetchLang(); // axios
            const translation = factory.getTranslation(DATA, valueSelected); // Default = fr
            switch (valueSelected) {
                case C.LANG_FR:
                    label = translation["LANG_FR"];
                    break;
                case C.LANG_EN:
                    label = translation["LANG_EN"];
                    break;
                case C.LANG_DE:
                    label = translation["LANG_DE"];
                    break;
                default:
                    label = translation["LANG_FR"];
                    break;
            }
            langToState({ data: DATA, selected: valueSelected, translation, index, label }); // Init lang
        } catch (err) {
            // Error while fetching, set Error
            errorToState({ type: ERROR_TYPES.LANG_ERROR, message: translation["LANG_ERROR"] }); // Redux error
            langToState({ label: 'Français', translation, index: 0, selected: C.LANG_FR }); // error, lang file not loaded, default lang is fr
        }
    }

    // Fermeture du menu de langue
    const handleClose = () => {
        setAnchorEl(null);
    };

    // Modification de la langue en sélectionnant celle-ci
    const handleMenuItemClick = (event, index) => {
        const selectedLang = options[index];
        const factory = new LangFactory();
        const translation = factory.getTranslation(lang.data, selectedLang.value);
        let label = '';
        switch (selectedLang.value) {
            case C.LANG_FR:
                label = translation["LANG_FR"];
                break;
            case C.LANG_EN:
                label = translation["LANG_EN"];
                break;
            case C.LANG_DE:
                label = translation["LANG_DE"];
                break;
            default:
                label = translation["LANG_FR"];
                break;
        }
        if (Object.keys(translation).length > 0) {
            langToState({ ...lang, selected: selectedLang.value, translation, index, label }); // Init lang
        } else {
            errorToState({ type: ERROR_TYPES.LANG_ERROR, message: translation["LANG_ERROR"] }); // Error, lang file not loaded
        }
        const manager = new LocalStorageManager();
        manager.setItem(manager._CONSTANTS.SELECTED_LANG, selectedLang.value);
        setAnchorEl(null);
    };

    // Triggered to open the menu
    const openMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const currentIcon = () => {
        switch (lang.selected) {
            case C.LANG_FR:
                return FLAG_FR;
            case C.LANG_EN:
                return FLAG_EN;
            case C.LANG_DE:
                return FLAG_DE;
            default:
                return FLAG_FR;
        }
    }

    return (
        <React.Fragment>
            <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={openMenu}
            >
                <img className={classes.selectedFlag} src={currentIcon()} alt="current-flag" />
                <Icon>arrow_drop_down</Icon>
            </Button>
            <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {options.map((option, index) => (
                    <MenuItem
                        key={option.value}
                        selected={index === lang.index}
                        onClick={event => handleMenuItemClick(event, index)}
                    >
                        <ListItemIcon className={classes.flagButton}>
                            <img className={classes.flag} src={option.icon} alt="flag" />
                        </ListItemIcon>
                    </MenuItem>
                ))}
            </Menu>
        </React.Fragment>

    )
}
// // // 
// Redux connexion
const mapStateToProps = state => ({
    lang: state.lang
})

const mapDispatchToProps = dispatch => {
    return {
        langToState: (val) => {
            dispatch(
                setLangAction(val) // Update the lang
            )
        },
        errorToState: (val) => {
            dispatch(
                setErrorAction(val) // error
            )
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LangPicker);