import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
    createStyles({
        content: {
            marginTop: theme.spacing(1),
            color: 'gray'
        }
    })
);

const ConnectionResult = ({ translation, error }) => {
    const classes = useStyles();

    return (
        <div>
            {error ?
                <React.Fragment>
                    <Typography component="h4">{translation["FORM_RESULT_TITLE_ERROR"]}</Typography>
                    <Typography className={classes.content} component="h4">{translation["FORM_RESULT_CONTENT_ERROR"]}</Typography>
                </React.Fragment>
                :
                <React.Fragment>
                    <Typography component="h4">{translation["FORM_RESULT_TITLE_SUCCESS"]}</Typography>
                    <Typography className={classes.content} component="h4">{translation["FORM_RESULT_CONTENT_SUCCESS"]}</Typography>
                </React.Fragment>
            }
        </div>
    )
}
// // // 
// Redux connexion
const mapStateToProps = state => ({
    translation: state.lang.translation
})

const mapDispatchToProps = dispatch => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ConnectionResult);