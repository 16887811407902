import React, { useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Grid, AppBar, Toolbar, CssBaseline } from '@material-ui/core';
import { connect } from 'react-redux';
import { updateViewAction } from '../../../store/actions/viewActions';
import { LangPicker } from '../../LangPicker';
import { RESA_LOGO } from '../../../assets';
import { Searchbar } from '../../Searchbar';
import { WithoutBuffer } from '../../BufferInfo';
import clsx from 'clsx';
import { SnackError } from '../../error/SnackError';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: 'flex',
            overflowY: 'hidden'
        },
        selectedListItem: {
            color: theme.palette.secondary.main,
            background: "#EAEAEA"
        },
        appBar: {
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            })
        },
        menuButton: {
            marginRight: 36,
        },
        hide: {
            display: 'none',
        },
        content: {
            width: '100%',
            height: '100vh'
        },
        appBarBrand: {
            display: 'flex',
            justifyContent: 'center'
        },
        logo: {
            '& img': {
                maxHeight: "50px",
                cursor: "pointer"
            },
            [theme.breakpoints.only('xs')]: {
                display: 'none'
            }
        },
        appBarLang: {
            display: 'flex',
            justifyContent: 'flex-end'
        }
    }),
);
// Base Page template of the application
const Page = (props) => {
    const { component: Component, path, name, displayText, viewToState } = props; // Component to inject

    useEffect(() => {
        viewToState({ currentPage: { path, name, displayText } }); // set the current page (route = { path: '/', name: '/' })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path, name])

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CssBaseline />
            <SnackError />
            <WithoutBuffer>
                <AppBar
                    position="fixed"
                    className={classes.appBar}
                    color="primary"
                >
                    <Toolbar>
                        <Grid container alignItems="center">
                            <Grid item xs>
                                <Searchbar />
                            </Grid>
                            <Grid item className={clsx(classes.appBarBrand, classes.logo)} xs >
                                <a href="http://resa.be" target="_blank" rel="noopener noreferrer">
                                    <img src={RESA_LOGO} alt="Logo Resa" />
                                </a>
                            </Grid>
                            <Grid item xs className={classes.appBarLang}>
                                <LangPicker />
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </WithoutBuffer>
            <div className={classes.content}>
                {/** Component injection */}
                <Component {...props} />
            </div>
        </div>
    )
}
// // // 
// Redux connexion
const mapStateToProps = state => ({
    currentPage: state.view.currentPage, // Current location in the app
    translation: state.lang.translation
})

const mapDispatchToProps = dispatch => {
    return {
        viewToState: (val) => {
            dispatch(
                updateViewAction(val) // Update the view
            )
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Page);