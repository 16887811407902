import RestService from "../RESTService";
import axios from 'axios';

export default class BufferService extends RestService {
    constructor() {
        super(`${process.env.REACT_APP_API_BASE_URL}/buffers`);
    }

    // Récupération des buffers depuis l'API
    fetchBuffers = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const { data } = await axios.get(super.getUri());
                resolve(data); // Success
            } catch (err) {
                reject(err); // Error
            }
        })
    } 
}