import { constants as C } from '../actions/constants';

export const selectedPlace = (state = {}, action) => {
    switch (action.type) {
        case C.SET_SELECTED_PLACE:
            return action.payload
        case C.UPDATE_SELECTED_PLACE:
            return { ...state, ...action.payload }
        default:
            return state
    }
}