// Module permettant de récupérer un objet contenant l'ensemble des données de langue nécessaires
// Par défaut, si aucune langue n'a été configurée, le fichier renvoyé sera en FR
export default class LangFactory {

    // Méthode de récupération d'un objet contenant uniquement les valeur de traduction dans la langue sélectionnée
    getTranslation(data = {}, selected = 'fr') {
        const translated = {};
        if (data && selected) {
            Object.keys(data).forEach(key => {
                const translation = data[key][selected] ? data[key][selected] : 'No translation found'; // handle error lang selected
                translated[key] = translation;
            });
        }
        return translated;
    }
}