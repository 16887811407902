import { constants as C } from './constants';

export const setSelectedPlaceAction = (val) => (
    {
        type: C.SET_SELECTED_PLACE,
        payload: val
    }
);

export const updateSelectedPlaceAction = (val) => (
    {
        type: C.UPDATE_SELECTED_PLACE,
        payload: val
    }
);