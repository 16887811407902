// Module de gestion du local storage

export default class LocalStorageManager {
    constructor() {
        this._CONSTANTS = {
            LANG_DATA: "PROMOGAZ_LANG_DATA",
            SELECTED_LANG: "PROMOGAZ_SELECTED_LANG"
        }
    }

    setItem = (key, value) => {
        const stringify = JSON.stringify(value);
        localStorage.setItem(key, stringify);
    }

    getItem = (key) => {
        const val = JSON.parse(localStorage.getItem(key));
        return val;
    }
}