import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, Slide, Button, DialogTitle } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ExternalLink } from '../ui-kit/ExternalLink';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

// Transition used to make the dialog come from the bottom
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            [theme.breakpoints.up('sm')]: {
                margin: theme.spacing(1)
            }
        },
        title: {
            background: theme.palette.primary.main,
            color: 'white'
        },
        link: {
            color: theme.palette.primary.main,
            cursor: 'pointer',
        }
    })
);
const WelcomeBanner = ({ translation, history }) => {
    const [open, setOpen] = useState(true);
    const classes = useStyles();

    const toPrivacyPage = () => {
        history.push('/privacy');
    }

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            TransitionComponent={Transition}>
            <DialogTitle className={classes.title} color="primary">
                {translation["WELCOME_BANNER_TITLE"] ? translation["WELCOME_BANNER_TITLE"] : ''}
            </DialogTitle>
            <DialogContent className={classes.root}>
                <DialogContentText>
                    {translation["WELCOME_BANNER_1"]}
                    <span onClick={toPrivacyPage} className={classes.link} >
                        {translation["WELCOME_BANNER_PRIVACY_LINK"]}
                    </span>
                    .
                </DialogContentText>
                <DialogContentText>
                    {translation["WELCOME_BANNER_2"]}
                    <ExternalLink href="https://klim-cicc.be/" label={translation["WELCOME_BANNER_REQUEST_LINK"]} />
                    {translation["WELCOME_BANNER_3"]}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => setOpen(false)}>
                    {translation["WELCOME_BANNER_CONTINUE"] ? translation["WELCOME_BANNER_CONTINUE"] : ''}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
WelcomeBanner.propTypes = {
    transaltion: PropTypes.object
}
// // //
// Redux connexion
const mapStateToProps = state => ({
    translation: state.lang.translation
})

const mapDispatchToProps = dispatch => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WelcomeBanner));