import { combineReducers } from 'redux';
import { view } from './viewReducer';
import { lang } from './langReducer';
import { mapProperties } from './mapPropertiesReducer';
import { selectedPlace } from './selectedPlaceReducer';
import { formValue } from './formValueReducer';
import { error } from './errorReducer';
import { constants as C } from '../actions/constants';

/** BASE OF ALL REDUCERS */
const appReducer = combineReducers({
    view, // { currentPage: { path, name, displayText} , bufferDisplay}
    lang, // { selected : "", data: {}, translation: {}, label, index }
    mapProperties, // { center, zoom, loading }
    selectedPlace, // { center, zoom, completeAddress, composedAddress, commune, ...}
    formValue, // { firstName, lastName, recaptcha,...}
    error, // { message, type }
});

// Root reducer is used to clear the whole store. This is used for logout actions
const rootReducer = (state, action) => {
    if (action.type === C.CLEAR_ROOT) return appReducer({}, action);
    return appReducer(state, action);
}
export default rootReducer;