import RestService from "../RESTService";
import axios from 'axios';

export default class ConnectionService extends RestService {
    constructor() {
        super(`${process.env.REACT_APP_API_BASE_URL}/connection`);
    }

    // Recherche des lieux
    connectionRequest = (val) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { data } = await axios.post(`${super.getUri()}`, val);
                resolve(data); // Success
            } catch (err) {
                reject(err); // Error
            }
        })
    }
}