import { constants as C } from '../actions/constants';
import defaultValues from '../default/formValue.json';

export const formValue = (state = defaultValues, action) => {
    switch (action.type) {
        case C.UPDATE_FORM_VALUE:
            return { ...state, ...action.payload }
        default:
            return state
    }
}