import React from 'react';
import clsx from 'clsx';
import { Typography, AppBar, Toolbar, Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { RESA_LOGO } from '../../assets';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: "#6C6B6E",
            height: '100vh',
            flexDirection: 'column'
        },
        appBar: {
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            })
        },
        logo: {
            '& img': {
                maxHeight: "50px",
                cursor: "pointer"
            },
            [theme.breakpoints.only('xs')]: {
                display: 'none'
            }
        },
        logoContainer: {
            display: 'flex',
            justifyContent: 'center'
        },
    })
);

// 404 not found component
// This component handle all routes that are not valid
const NotFoundPage = () => {
    const classes = useStyles();
    return (
        <React.Fragment>
            {/** TOPBAR */}
            <AppBar
                position="fixed"
                className={classes.appBar}
                color="primary"
            >
                <Toolbar>
                    <Grid container alignItems="center">
                        <Grid item xs className={clsx(classes.logo, classes.logoContainer)}>
                            <a href="http://resa.be" target="_blank" rel="noopener noreferrer">
                                <img src={RESA_LOGO} alt="Logo Resa" />
                            </a>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <div className={classes.root}>
                <Typography component="h3">404 Not Found</Typography>
                <Typography component="p">We didn't found the page you were looking for.</Typography>
                <Link to="/">Back to map</Link>
            </div>
        </React.Fragment>
    );
}

export default NotFoundPage;